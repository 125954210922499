import { IMAGE_API_ERROR, RECIEVE_IMAGE } from "../actions/imageActions";

const initialState = {
  data: [],
  error: null,
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIEVE_IMAGE:
      return {
        ...state,
        data: action.payload,
        error: null,
      };

    case IMAGE_API_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default imageReducer;

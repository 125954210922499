import React from "react";
import img6 from "../../assets/img6.jpg";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../utils/getSetLocalStorage";

const ThemeSetupFinish = ({ theme, features }) => {
  const navigation = useNavigate();
  const handleFormSubmit = () => {
    navigation("/main?p=dashboard");
    // Object.keys(checkedItems).filter((key) => checkedItems[key])
  };

  const handleNavigate = () => {
    const store = getItem("shop").split(".")[0]; // get store from shop domain
    const url = `https://admin.shopify.com/store/${store}/themes`;
    window.open(url, "_blank"); // Open link in a new tab
  };

  return (
    <div className="new-container">
      <div className="content-area">
        <div
          className="d-flex"
          style={{
            background: "#ffffff",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <div className="left-portion">
            <div>
              <img
                style={{
                  height: "30rem",
                  display: "flex",
                  borderRadius: "8px 0px 0px 8px",
                }}
                src={img6}
                alt="data-sync"
              />
            </div>
          </div>
          <div
            className="right-portion"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <p className="theme-sync-heading">
              Your store is ready to launch with MetaXpert
            </p>
            <p className="theme-sync-content" style={{ fontSize: "14px" }}>
              We have successfully set up the{" "}
              <span style={{ fontWeight: "700" }}>{features}</span> for the
              theme <span style={{ fontWeight: "700" }}>{theme}</span>
            </p>
            <div className="d-flex">
              <button className="dashboard-take" onClick={handleFormSubmit}>
                Take me to dashboard
              </button>
              <button className="preview-button" onClick={handleNavigate}>
                Preview on storefront
              </button>
            </div>
            <a
              href="mailto:customersupport@laitkor.com"
              target="blank"
              className="theme-sync-content"
              style={{
                fontSize: "14px",
                fontWeight: "700",
                marginTop: "60px",
              }}
            >
              Contact us for help
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSetupFinish;

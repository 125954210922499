import "./WelcomePage.css";
import image1 from "../../assets/slide1.jpg";
import { Link } from "react-router-dom";

const WelcomePage1 = () => {
  return (
    <div className="welcome-container">
      <div className="content-area">
        <div className="imageAndTextContainer">
          <div className="left-portion">
            <img
              src={image1}
              className="slider-images"
              alt="image1"
              width={450}
            />
          </div>
          <div className="right-portion">
            <p className="manage_Store">Welcome to MetaXpert!</p>
            <p className="slide-text">
              Thank you for installing MetaXpert. <br /> Let’s go through the
              best features to get the most out of our app!
            </p>
            <Link className="welcome-page-link-button" to="/theme-setup">
              <button className="button-next btn">Let's Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage1;

import "./FilterLayout.css";

const FilterLayout = () => {
  return (
    <div className="text-center mt-5 mb-5">
      {/* Mobile view: 3 rows, 1 column */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <p>Vertical</p>
            <div className="border d-flex flex-row">
              <div className="d-flex flex-column p-3 sidebar">
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                {/* Add more skeleton items as needed */}
              </div>
              <div className="row aside-right mx-auto">
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
              </div>
            </div>
            <input
              style={{ border: "1px solid black", marginTop: "10px" }}
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio1"
            ></input>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <p>Horizontal</p>
            <div className="">
              <div className="header-skeleton">
                <div className="header-skeleton-item"></div>
                <div className="header-skeleton-item"></div>
                <div className="header-skeleton-item  "></div>
                {/* Add more skeleton items as needed */}
              </div>
              <div className="row below mx-auto">
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-4 col-lg-4">
                  <div className="bg-white block"></div>
                </div>
              </div>
            </div>
            <input
              style={{ border: "1px solid black", marginTop: "10px" }}
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio2"
            ></input>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-5">
            <p>Off-Canvas</p>
            <div className="border d-flex">
              <div
                className="d-flex flex-column p-3 sidebar"
                style={{ opacity: "0.9" }}
              >
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
              </div>
              <div className="row aside-right" style={{ marginLeft: "-75px" }}>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
                <div className="col col-md-6 col-lg-6">
                  <div className="bg-white block"></div>
                </div>
              </div>
            </div>
            <input
              style={{
                border: "1px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio3"
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterLayout;

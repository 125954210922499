export const REQUEST_DELETE_FILTER = "REQUEST_DELETE_FILTER";
export const RECIEVE_DELETE_FILTER = "RECIEVE_DELETE_FILTER";
export const DELETE_FILTER_API_ERROR = "DELETE_FILTER_API_ERROR";

export const requestDeleteFilter = (filter_id) => ({
  type: REQUEST_DELETE_FILTER,
  payload: filter_id,
});

export const receiveDeleteFilter = (image) => ({
  type: RECIEVE_DELETE_FILTER,
  payload: image,
});

export const deleteFilterApiError = (error) => ({
  type: DELETE_FILTER_API_ERROR,
  payload: error,
});

import { Carousel } from "react-responsive-carousel";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import WelcomePage1 from "../../components/Sliders/Welcomepage1";
import WelcomePage2 from "../../components/Sliders/Welcomepage2";
import WelcomePage3 from "../../components/Sliders/Welcomepage3";
import WelcomePage4 from "../../components/Sliders/Welcomepage4";
import WelcomePage5 from "../../components/Sliders/Welcomepage5";
import WelcomePage6 from "../../components/Sliders/welcomepage6";
import "../../components/Sliders/WelcomePage.css";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getItem, storeItem } from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "./WelcomePage.css";
import { checkVisited, saveVisited } from "../../services/welcomeService";

const WelcomePage = () => {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get("shop");

  console.log(
    "REACT_APP_SHOPIFY_API_KEY: ",
    process.env.REACT_APP_SHOPIFY_API_KEY
  );

  const settings = {
    showStatus: false,
    showThumbs: false,
    stopOnHover: false,
    autoPlay: true,
    interval: 5000,
    dots: true,
    virtualList: true,
    duration: 20,
    renderIndicator: false,
  };

  const getShop = async () => {
    // use dynamic shop name inside shopify app
    // const shop = "juststudio.myshopify.com";
    // const shop = new URLSearchParams(window.location.search).get("shop");
    setLoading(true);

    // ${process.env.REACT_APP_REDIRECT_URI}
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/install?shop=${shop}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/connect`
      )
      .catch((err) => {
        console.log("error in install API: ", err);
        toast.error("Something went wrong. Please try again later");
        return;
      });

    console.log(
      "install res-: ",
      response.data,
      response?.data?.data,
      response?.data?.status
    );

    if (parseInt(response.data.status) === 0) {
      window.location.href = response.data.data;
      console.log("redirecting: ", response?.data?.data, window.location);
      // window.location = response.data.data;
      // window.location.replace(response.data.data);
    } else {
      const shopId = response?.data?.data[0];
      storeItem("shopId", shopId);
      storeItem("shop", response?.data?.data[1]);
      storeItem("token", response?.data?.data[2]);

      const data = await checkVisited(shopId);

      console.log("visited status: ", data);

      if (data === 0) {
        const data = await saveVisited(shopId);
        console.log("save visited status: ", data);
        setLoading(false);
        return;
      } else {
        navigation("/main?p=dashboard");
        setLoading(false);
        return;
      }
    }
  };

  const indicatorStyles = {
    background: "#d9d9d9",
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    display: "inline-block",
    margin: "0 8px",
  };

  useEffect(() => {
    getShop();
    // return () => {
    //   getShop();
    // };
  }, []);

  if (loading) {
    return (
      <div className="loaderParent">
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="step-slider">
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            containerId="containerA"
          />
          <ToastContainer />
        </div>
        <Carousel
          infiniteLoop
          showIndicators
          autoPlay
          className="Slider"
          {...settings}
          animationHandler="fade"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles, background: "#00f260" }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <div key="slide1">
            <WelcomePage1 />
          </div>
          <div key="slide2">
            <WelcomePage2 />
          </div>
          <div key="slide3">
            <WelcomePage3 />
          </div>
          <div key="slide4">
            <WelcomePage4 />
          </div>
          <div key="slide5">
            <WelcomePage5 />
          </div>
          <div key="slide6">
            <WelcomePage6 />
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default WelcomePage;

import { useState } from "react";
import "./ThemeItem.css";
import editIcon from "../../assets/edit.png";
import { getSelectedFeatures, themeSetup } from "../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { getItem } from "../../utils";
import { useNavigate } from "react-router-dom";

export const ThemeItem = ({ data, setExpandId, expandId }) => {
  const { name, id, shopify_theme_id, shop_id } = data;
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedFeatureList, setSelectedFeatureList] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);

  const getFeatures = async () => {
    setLoading(true);
    const data = await getSelectedFeatures(shopify_theme_id, shop_id);
    if (data === null) {
      setSelectedFeatureList(data);
      setLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }
    setSelectedFeatureList(data);
    setCheckedItems([]);
    data.map((feature) => {
      if (feature.status === true) {
        setCheckedItems((items) => [...items, feature.feature_id]);
      }
      return null;
    });
    setLoading(false);
    return;
  };

  const handleExpand = (id) => {
    if (id === expandId) {
      setExpandId(0);
      return;
    } else {
      setExpandId(id);
      getFeatures();
      return;
    }
  };

  const handleCheckboxChange = (e) => {
    const checkId = e.target.value;
    if (e.target.checked) {
      setCheckedItems((items) => [...items, checkId]);
    } else {
      setCheckedItems(checkedItems.filter((id) => id !== checkId));
    }
  };

  const handleNavigate = () => {
    const store = getItem("shop").split(".")[0]; // get store from shop domain
    const url = `https://admin.shopify.com/store/${store}/themes`;
    window.open(url, "_blank"); // Open link in a new tab
  };

  const handleDisable = () => {
    navigation(`/main?p=theme-disable/${shopify_theme_id}`);
  };

  const handleFeaturesSave = async () => {
    setSaveLoading(true);
    const shopId = getItem("shopId");
    const data = await themeSetup(shopId, shopify_theme_id, checkedItems);

    if (data === null) {
      setSaveLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }

    if (data.status === 201) {
      setSaveLoading(false);
      // conditionally render the theme page based on the url
      // if (isThemeSettingsPage === "run-setup") navigate("/main?p=sync-theme");
      // else navigate("/sync-theme");
      setExpandId(0);

      return;
    }

    setSaveLoading(false);
    return;
  };

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <div className={`listContainer borderTop`}>
        <div className="listRightItem" onClick={() => handleExpand(id)}>
          <h6 style={{ marginBottom: "3px" }}>{name}</h6>
          <i
            className={`fa fa-angle-${id === expandId ? "up" : "down"}`}
            aria-hidden="true"
          ></i>

          {/* <p style={{ fontSize: "14px", marginBottom: "0px" }}>
            Last updated: {moment(updated_at).format("MMMM Do YYYY, h:mm:ss a")}
          </p> */}
        </div>
        <div className="listLeftItem">
          <div className="itemBtn" onClick={handleDisable}>
            <p className="btnText">Disable</p>
          </div>
          <div className="itemBtn" onClick={handleNavigate}>
            <p className="btnText">Preview</p>
          </div>
        </div>
      </div>
      <div
        className="featuresContainer"
        style={{ display: id === expandId ? "" : "none" }}
      >
        <div className="featuresLeft">
          <h6>Which functions would you like to uninstall?</h6>
          {loading ? (
            <div className="loaderParent">
              <Spinner animation="border" size="sm" variant="primary" />
            </div>
          ) : (
            selectedFeatureList &&
            selectedFeatureList.map((item, index) => (
              <div key={index} className="form-check">
                <input
                  className="form-check-input"
                  name="checkbox"
                  type="checkbox"
                  id={`checkbox-${index}`}
                  value={item.feature_id}
                  checked={checkedItems.includes(item.feature_id) || false}
                  onChange={(e) => handleCheckboxChange(e)}
                />
                <label className="form-check-label" for={`checkbox-${index}`}>
                  {item.feature}
                </label>
              </div>
            ))
          )}
        </div>
        <div className="featuresRight">
          <button onClick={() => setExpandId(0)} className="cancelBtn">
            Cancel
          </button>
          <button onClick={handleFeaturesSave} className="saveBtn">
            {saveLoading ? <Spinner animation="border" size="sm" /> : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};

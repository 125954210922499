// saga.js

import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_NEW_FILTER_REQUEST,
  addNewFilterApiError,
  addNewFilterResponse,
} from "../actions/addNewFilter";
import axios from "axios";
import { headers } from "./header";

function* handlePostRequest(action) {
  try {
    const response = yield call(
      axios.post,
      "https://metaapp-api.dealopia.com/api/filters",
      action.payload,
      { headers: headers }
    );
    yield put(addNewFilterResponse(response.data));
  } catch (error) {
    yield put(addNewFilterApiError(error.message));
  }
}

function* watchAddNewFilter() {
  yield takeLatest(ADD_NEW_FILTER_REQUEST, handlePostRequest);
}

export default watchAddNewFilter;

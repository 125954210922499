import {
  ADD_NEW_FILTER_API_ERROR,
  ADD_NEW_FILTER_REQUEST,
  ADD_NEW_FILTER_RESPONSE,
} from "../actions/addNewFilter";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const addNewFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_FILTER_REQUEST:
      return { ...state, loading: true, error: null };

    case ADD_NEW_FILTER_RESPONSE:
      return { ...state, loading: false, data: action.payload };

    case ADD_NEW_FILTER_API_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default addNewFilterReducer;

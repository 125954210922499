import axios from "axios";
import { headers } from "../redux/sagas/header";
import { getItem } from "../utils"

export const getThemes = async () => {
    const data = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}/api/theme/${getItem("shopId")}`, { headers: headers }).then((res) => {

        if (res.data.message === "Theme not found.") {
            return null;
        }

        return res.data.theme;
    }).catch((err) => {
        console.log("theme error: ", err)
        return null;
    })

    return data;
}

export const getActiveInactiveThemes = async (key) => {
    const data = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}/api/theme/${key === "enabled" ? "selected-theme" : "nonselected-theme"}/${getItem("shopId")}`, { headers: headers }).then((res) => {

        if (res.data.message === "Theme not found.") {
            return null;
        }

        return key === "enabled" ? res.data.Active_themes : res.data.Inactive_themes;
    }).catch((err) => {
        console.log("theme error: ", err)
        return null;
    })

    return data;
}

export const getFeatures = async () => {

    const data = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}/api/feature`, { headers: headers }).then((res) => {

        if (!res.data) {
            return null;
        }

        return res.data.data;
    }).catch((err) => {
        console.log("features error: ", err)
        return null;
    })

    return data;
}

export const getSelectedFeatures = async (themeId, storeId) => {

    const data = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}/api/themewithfeatures/${themeId}/${storeId}`, { headers: headers }).then((res) => {

        if (!res.data) {
            return null;
        }

        if (!res.data.features) {
            return res.data.data
        }

        return res.data.features;
    }).catch((err) => {
        console.log("features error: ", err)
        return null;
    })

    return data;
}

export const themeSetup = async (shopId, themeId, featureIds) => {

    const data = await axios.post(`${process.env.REACT_APP_FRONTEND_URL}/api/setup-theme`, {
        "store_id": shopId,
        "theme_id": themeId,
        "feature_ids": featureIds
    }, { headers: headers }).then((res) => {

        if (!res.data) {
            return null;
        }

        return res.data;
    }).catch((err) => {
        console.log("features error: ", err)
        return null;
    })

    return data;
}
import axios from "axios"

export const uninstallApp = (shopId) => {

    let payload = JSON.stringify({
        "shop_id": shopId,
        "id": shopId
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/uninstall`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    const res = axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error during uninstalling: " + error);
            return null;
        });

    return res;
}
export const REQUEST_EDIT_FILTER = "REQUEST_EDIT_FILTER";
export const RECIEVE_EDIT_FILTER = "RECIEVE_EDIT_FILTER";
export const EDIT_FILTER_API_ERROR = "EDIT_FILTER_API_ERROR";

export const requestEditFilter = (payload, filter_id) => ({
  type: REQUEST_EDIT_FILTER,
  payload: { payload, filter_id },
});

export const receiveEditFilter = (editedFilter) => ({
  type: RECIEVE_EDIT_FILTER,
  payload: editedFilter,
});

export const editFilterApiError = (error) => ({
  type: EDIT_FILTER_API_ERROR,
  payload: error,
});

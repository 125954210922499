import { useState } from "react";
import "./CustomPagerDropdown2.css";

const CustomPagerDropdown = ({
  itemsPerPageOptions,
  onItemsPerPageChange,
  heading,
}) => {
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(
    itemsPerPageOptions[0]
  );

  const handleItemsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedItemsPerPage(newValue);
    onItemsPerPageChange(newValue);
  };

  return (
    <div className="pagination-dropdown">
      <label style={{ marginRight: "5px" }} htmlFor="itemsPerPage">
        {heading}
      </label>
      <select
        className="select-dropdown"
        id="itemsPerPage"
        defaultValue={selectedItemsPerPage}
        onChange={handleItemsPerPageChange}
      >
        {itemsPerPageOptions.map((option) => (
          <option key={option} defaultValue={option}>
            {option}
          </option>
        ))}
      </select>
      <label className="ml-2" htmlFor="itemsPerPage">
        entries
      </label>
    </div>
  );
};

export default CustomPagerDropdown;

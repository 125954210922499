import edit from "../../assets/action-icons/Vector.svg";
import remove from "../../assets/action-icons/trash-2.svg";
import { TooltipItem } from "../Tooltip/Tooltip";

export const MetafieldItem = ({ onEdit, onDelete }) => {
  return (
    <div className="tableListItem tableListItemText">
      <div className="nameItem">Custom</div>
      <div className="keyItem">Material</div>
      <div className="appliesItem">
        <div className="appliesItemValue">Display</div>
        <div className="appliesItemValue">Search</div>
        <div className="appliesItemValue">filter by Option</div>
        <div className="appliesItemValue">Sort by Option</div>
      </div>
      <div className="actionItem">
        <div className="action-item-container">
          <TooltipItem title="Edit Metafield" id="tooltip">
            <img
              onClick={() => onEdit()}
              className="icon-1-2"
              src={edit}
              alt="edit"
            />
          </TooltipItem>
          <TooltipItem title="Delete Metafield" id="tooltip">
            <img
              onClick={() => onDelete(6)}
              className="icon-3"
              src={remove}
              alt="remove"
            />
          </TooltipItem>
        </div>
      </div>
    </div>
  );
};

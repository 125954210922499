import React from "react";
import { useNavigate } from "react-router-dom";
import AddNewMergedValue from "./AddNewMergedValue";

const AddMergedValue = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/main?p=merge-values");
  };
  return (
    <div className="ml-5 mt-5 mr-5">
      <div className="d-flex">
        <div
          onClick={goBack}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            background: "#f6f6f7",
            padding: "5px",
            border: "2px solid #d4d6d9",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
        <p className="h4">Add merged value</p>
      </div>
      <p className="mt-4" style={{ fontSize: "14px" }}>
        Merged type
      </p>
      <p className="h6">Type</p>
      <input
        style={{
          width: "50%",
          borderRadius: "10px",
          border: "2px solid #d4d6d9",
          marginTop: "10px",
          marginLeft: "20px",
          padding: "5px",
        }}
        placeholder="Select Type"
      />
      <hr className="mt-5" />

      <p className="h6 mt-5">Merged Value</p>
      <div>
        <table width="100%" style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ background: "white" }}>
              <th
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "white",
                  color: "black",
                  borderBottom: "2px solid",
                }}
              >
                Status
              </th>
              <th
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "white",
                  color: "black",
                  borderBottom: "2px solid",
                }}
              >
                Merged Values
              </th>
              <th
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "white",
                  color: "black",
                  borderBottom: "2px solid",
                }}
              >
                Current Values
              </th>
              <th
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "white",
                  color: "black",
                  borderBottom: "2px solid",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            <tr>
              <td colSpan="4">There is no current merged value</td>
            </tr>
            <tr>
              <td colSpan="4">
                <button
                  type="button"
                  style={{ width: "200px" }}
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  + Add new merged value
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <AddNewMergedValue id="exampleModalCenter" />
      </div>
    </div>
  );
};

export default AddMergedValue;

import { useEffect } from "react";
import { useDispatch } from "react-redux";

const usePagination = (currentPage, itemsPerPage, requestData) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestData(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage, requestData]);
};

export default usePagination;

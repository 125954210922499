import React from "react";

const AddNewMergedValue = ({ id }) => {
  return (
    <div>
      <div
        className="modal fade"
        id={id}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add new merged value
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="h6 ml-0">Merged value</p>
              <input
                type="text"
                style={{
                  border: "2px solid #d4d6d9",
                  borderRadius: "10px",
                  width: "100%",
                  padding: "5px",
                }}
              />
              <p className="h6 mt-3 ml-0">Current values</p>
              <input
                type="text"
                placeholder="Select Values"
                style={{
                  border: "2px solid #d4d6d9",
                  borderRadius: "10px",
                  width: "100%",
                  padding: "5px",
                }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewMergedValue;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syncStore {
  height: 145px;
  font-family: "Roboto";
  padding: 15px;
  border: 1px solid rgba(217, 217, 217, 1);
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage/DashboardContent/SyncStore/SyncStoreCSS.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".syncStore {\n  height: 145px;\n  font-family: \"Roboto\";\n  padding: 15px;\n  border: 1px solid rgba(217, 217, 217, 1);\n  background: #ffffff;\n  border-radius: 8px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import "./AddEditFilterOption.css";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../assets/close.png";
import Form from "react-bootstrap/Form";

export const AddEditFilterOptionModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="modalContainer">
                <div className="ModalHeaderContainer">
                    <h6>Add Metafield</h6>
                    <img
                        onClick={onHide}
                        className="closeIcon"
                        src={closeIcon}
                        alt="close"
                    />
                </div>
                <div className="inputContainer">
                    <div className="InputParent">
                        <Form.Label htmlFor="namespace">Namespace</Form.Label>
                        <Form.Control type="text" id="namespace" />
                    </div>
                    <div className="InputParent">
                        <Form.Label htmlFor="key">Key</Form.Label>
                        <Form.Control type="text" id="key" />
                    </div>
                </div>
                <div className="checkContainer">
                    <Form.Label htmlFor="checkParent">Applies to</Form.Label>
                    <div className="checkParent" id="checkParent">
                        <Form.Check
                            label="Display"
                            name="display"
                            type="checkbox"
                            id="display"
                        />
                        <Form.Check
                            label="Search"
                            name="search"
                            type="checkbox"
                            id="search"
                        />
                        <Form.Check
                            label="Filter by option"
                            name="filter"
                            type="checkbox"
                            id="filter"
                        />
                        <Form.Check
                            label="Sort by option"
                            name="sort"
                            type="checkbox"
                            id="sort"
                        />
                    </div>
                </div>
                <div className="footer">
                    <div onClick={onHide} className="cancelBtn">
                        Cancel
                    </div>
                    <div className="saveBtn">Save</div>
                </div>
            </div>
        </Modal>
    );
};

import { useNavigate } from "react-router-dom";
import { getItem } from "../../utils";

const ThemeSetupNextPage = () => {
  const navigation = useNavigate();
  const shopifyThemeId = getItem("selectedThemeId");

  const handleNavigate = () => {
    navigation("/sync-theme");
    const store = getItem("shop").split(".")[0]; // get store from shop domain
    const url = `https://admin.shopify.com/store/${store}/themes/${shopifyThemeId}/editor?previewPath=%2Fcollections%2Fall&context=apps`;
    window.open(url, "_blank"); // Open link in a new tab
  };

  return (
    <div className="content-area pt-5 pb-2">
      <div
        style={{
          background: "#ffffff",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <div className="disableHeaderContainer">
          <h4
            className="pt-2 ml-2"
            style={{ fontFamily: "Roboto", fontWeight: "700" }}
          >
            Theme Setup
          </h4>
        </div>
        <div className="disableBodyContainer">
          <p className="disableText">
            Enable <b>MetaXpert</b> app embed blocks in{" "}
            <span className="disableThemeText">
              Theme -&gt; customize -&gt; App embeds
            </span>
          </p>
        </div>
        <div className="disableButtonContainer">
          <button className="backBtn" onClick={() => navigation(-1)}>
            Back
          </button>
          <button className="GoToThemeBtn" onClick={handleNavigate}>
            Go to Theme
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeSetupNextPage;

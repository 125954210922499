// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  font-size: 15px;
}

.ml-7 {
  margin-left: 7rem;
}

.w-20 {
  width: 58%;
}

.mr-7 {
  margin-right: 7rem;
}

.mt-4x {
  margin-top: 4rem;
}

.font-sm {
  font-size: 15px;
}

.font-md {
  font-size: 18px;
}

.clear-button {
  background: none;
  margin-top: 2px;
  padding: 0;
}

.font-input {
  width: 350px;
  border: 1px solid #c0c0c0;
  border-width: 2px;
  border-radius: 10px;
  padding: 4px;
}

.font-weight-select {
  width: 200px;
  background: none;
  border: 1px solid #c0c0c0;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FilterTree/FilterTree.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".label {\n  font-size: 15px;\n}\n\n.ml-7 {\n  margin-left: 7rem;\n}\n\n.w-20 {\n  width: 58%;\n}\n\n.mr-7 {\n  margin-right: 7rem;\n}\n\n.mt-4x {\n  margin-top: 4rem;\n}\n\n.font-sm {\n  font-size: 15px;\n}\n\n.font-md {\n  font-size: 18px;\n}\n\n.clear-button {\n  background: none;\n  margin-top: 2px;\n  padding: 0;\n}\n\n.font-input {\n  width: 350px;\n  border: 1px solid #c0c0c0;\n  border-width: 2px;\n  border-radius: 10px;\n  padding: 4px;\n}\n\n.font-weight-select {\n  width: 200px;\n  background: none;\n  border: 1px solid #c0c0c0;\n  border-width: 1px;\n  border-radius: 10px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

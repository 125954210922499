import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ThemeSetup.css";
import { ThemeItem } from "../../components/ThemeListItem/ThemeItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getActiveInactiveThemes, getThemes } from "../../services";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const ThemeSetupHomepage = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [themeList, setThemeList] = useState(null);
  const [expandId, setExpandId] = useState(0);
  const [key, setKey] = useState("enabled");

  const getTheme = async (key) => {
    setLoading(true);
    const data = await getActiveInactiveThemes(key);
    if (data === null) {
      setThemeList(data);
      setLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }
    setThemeList(data);
    setLoading(false);
    return;
  };

  useEffect(() => {
    return () => {
      getTheme(key);
    };
  }, []);

  const handleExpandId = (id) => {
    setExpandId(id);
    return;
  };

  const handleTabSelectAndGetTheme = (k) => {
    setKey(k);
    getTheme(k);
    return;
  };

  return (
    <div className="content-area">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <div className="d-flex justify-content-between my-4">
        <h5>
          <b>Themes</b>
        </h5>
        {/* <button
          className="run-setup-tbn"
          style={{ width: "150px" }}
          onClick={() => navigation("/main?p=theme-setting")}
        >
          Setup a new theme
        </button> */}
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => handleTabSelectAndGetTheme(k)}
        justify
        style={{
          marginBottom: "-23.5px",
        }}
      >
        <Tab eventKey="enabled" title="Enabled Features">
          <div className="my-4 tableContainer">
            {/* theme list item */}
            {loading ? (
              <div className="loadingContainer">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            ) : (
              <>
                {themeList?.map((item, key) => {
                  return (
                    <ThemeItem
                      data={item}
                      setExpandId={handleExpandId}
                      expandId={expandId}
                      key={key}
                    />
                  );
                })}
              </>
            )}
          </div>
        </Tab>
        <Tab eventKey="disabled" title="Disabled Features">
          <div className="my-4 tableContainer">
            {/* theme list item */}
            {loading ? (
              <div className="loadingContainer">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            ) : (
              <>
                {themeList?.map((item, key) => {
                  return (
                    <ThemeItem
                      data={item}
                      setExpandId={handleExpandId}
                      expandId={expandId}
                      itemId={key}
                      key={key}
                    />
                  );
                })}
              </>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ThemeSetupHomepage;

import { combineReducers } from "redux";
import productReducer from "./reducers/productReducer";
import variantReducer from "./reducers/variantReducer";
import imageReducer from "./reducers/imageReducer";
import variantByProductReducer from "./reducers/variantByProductsReducer";
import imageByProductReducer from "./reducers/imageByProductReducer";
import addNewFilterReducer from "./reducers/addNewFilterReducer";
import getAllFilterReducer from "./reducers/getAllFilterReducer";
import deleteFilterReducer from "./reducers/deleteFilterReducer";
import editFilterReducer from "./reducers/editFilterReducer";
import getSingleFilterReducer from "./reducers/getFilterByIdReducer";

const rootReducer = combineReducers({
  products: productReducer,
  variants: variantReducer,
  image: imageReducer,
  variantByProduct: variantByProductReducer,
  imageByProduct: imageByProductReducer,
  addNewFilter: addNewFilterReducer,
  getAllFilters: getAllFilterReducer,
  editFilter: editFilterReducer,
  getSingleFilter: getSingleFilterReducer,
  deleteFilter: deleteFilterReducer,
  // ... other reducers
});

export default rootReducer;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { requestVariantsByProduct } from "../../redux/actions/variantByProductsAction";

import { tableheaderData } from "./dataSource";
import useFetchImagesByProduct from "../../hooks/useFetchImagesByProduct";
import Loading from "../../reusables/Loading";

const VariantPage = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // fetching product id from the url params
  const currentParam = searchParams.get("p");
  const id_params = currentParam.split("/");
  const id = id_params[2];

  // fetching image data from the custom hook
  const imageData = useFetchImagesByProduct(id);

  // Access data from Redux store using useSelector
  const variants = useSelector((state) => state.variantByProduct.data);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.defaultValue);
  };

  if (!variants) {
    setLoading(true);
  }

  // fetching variants for a particular product
  useEffect(() => {
    dispatch(requestVariantsByProduct(id));
  }, [dispatch, id]);

  return (
    <div className="product-container-2">
      <p className="heading">Variant List</p>
      <div>
        <input
          className="search-input"
          type="text"
          placeholder="Search here"
          defaultValue={searchQuery}
          onChange={handleSearchChange}
        />
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <div className="table-container">
            <table className="table2">
              <thead>
                <tr>
                  {tableheaderData.map((item) => (
                    <th className="table-title" style={{ width: "150px" }}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {variants?.data
                  ?.filter((row) =>
                    Object?.values(row).some((defaultValue) => {
                      if (typeof defaultValue === "number") {
                        return defaultValue === searchQuery;
                      } else {
                        return String(defaultValue)
                          .toLowerCase()
                          .includes(searchQuery?.toLowerCase());
                      }
                    })
                  )
                  .map((productItem, index) => (
                    <tr key={productItem?.id}>
                      <td key={index}>{productItem?.title}</td>
                      {/* need to fix the bug when there is no image present */}
                      {imageData?.data?.map((image, imageIndex) =>
                        String(productItem?.variant_id) ===
                          String(image?.variant_ids) && image ? (
                          <td key={imageIndex}>
                            <img
                              src={image?.src}
                              alt={image?.alt}
                              width={100}
                              height={100}
                            />
                          </td>
                        ) : null
                      )}

                      <td>{productItem?.price}</td>
                      <td>{productItem?.inventory_quantity}</td>
                      <td>{productItem?.weight} oz</td>

                      <td>
                        <button id="button">View Detail</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default VariantPage;

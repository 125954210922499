// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.themestatus {
  height: 145px;
  font-family: "Roboto", Helvetica;
  margin-left: 0;
  padding: 15px;
  border: 1px solid rgba(217, 217, 217, 1);
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
}

p {
  font-size: 13px;
  margin-top: 2px;
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage/DashboardContent/ThemeStatus/ThemeStatusCSS.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,cAAc;EACd,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB","sourcesContent":[".themestatus {\n  height: 145px;\n  font-family: \"Roboto\", Helvetica;\n  margin-left: 0;\n  padding: 15px;\n  border: 1px solid rgba(217, 217, 217, 1);\n  background: #ffffff;\n  width: 100%;\n  border-radius: 8px;\n}\n\np {\n  font-size: 13px;\n  margin-top: 2px;\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestImageByPoduct } from "../redux/actions/imageByProduct";

const useFetchImagesByProduct = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestImageByPoduct(id));
  }, [dispatch, id]);

  const images = useSelector((state) => state.imageByProduct.data);

  return images;
};

export default useFetchImagesByProduct;

import React from "react";

import "./ActionIcons.css";
// import edit from "../../../assets/action-icons/Vector.svg";
import edit from "../../assets/action-icons/Vector.svg";
import copy from "../../assets/action-icons/copy.svg";
import remove from "../../assets/action-icons/trash-2.svg";
import { TooltipItem } from "../Tooltip/Tooltip";

const ActionIcons = ({ onDelete }) => {
  return (
    <div className="item-container">
      <TooltipItem title="Edit Filter" id="tooltip">
        <img className="icon-1-2" src={edit} alt="edit" />
      </TooltipItem>

      <TooltipItem title="Duplicate Filter" id="tooltip">
        <img className="icon-1-2" src={copy} alt="copy" />
      </TooltipItem>

      <TooltipItem title="Delete Filter" id="tooltip">
        <img
          onClick={() => onDelete()}
          className="icon-3"
          src={remove}
          alt="remove"
        />
      </TooltipItem>
    </div>
  );
};

export default ActionIcons;

import {
  RECIEVE_SINGLE_FILTER,
  SINGLE_FILTER_API_ERROR,
} from "../actions/getFilterById";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const getSingleFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIEVE_SINGLE_FILTER:
      return { ...state, loading: false, data: action.payload };
    case SINGLE_FILTER_API_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getSingleFilterReducer;

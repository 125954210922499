export const REQUEST_IMAGE = 'REQUEST_IMAGE'
export const RECIEVE_IMAGE = 'RECIEVE_IMAGE'
export const IMAGE_API_ERROR = 'IMAGE_API_ERROR'

export const requestImage = () => ({
    type: REQUEST_IMAGE,
}) 

export const receiveImage = (image) => ({
    type: RECIEVE_IMAGE,
    payload: image,
}) 

export const imageApiError = (error) => ({
    type: IMAGE_API_ERROR,
    payload: error,
}) 
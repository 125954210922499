// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
  height: 145px;
  font-family: "Roboto", Helvetica;
  margin-left: 0;
  padding: 15px;
  border: 1px solid rgba(217, 217, 217, 1);
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage/DashboardContent/Filters/FiltersCSS.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,cAAc;EACd,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".filters {\n  height: 145px;\n  font-family: \"Roboto\", Helvetica;\n  margin-left: 0;\n  padding: 15px;\n  border: 1px solid rgba(217, 217, 217, 1);\n  background: #ffffff;\n  width: 100%;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

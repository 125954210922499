import axios from "axios"

export const checkVisited = async (shopId) => {

    const config = {
        url: `${process.env.REACT_APP_API_URL}/visited?shop_id=${shopId}`,
        method: "GET"
    }

    const data = await axios(config).then((res) => {
        return res.data.visited_status;
    }).catch((err) => {

        if (err.response.status === 404) {
            return err.response.data.status;
        }

        console.log("error in get check store visit: ", err)
        return null;
    })

    return data;
}

export const saveVisited = async (shopId) => {

    const shopData = JSON.stringify({ "shop_id": shopId });

    const config = {
        url: `${process.env.REACT_APP_API_URL}/status?shop_id=${shopId}`,
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        data: shopData,
    }

    const res = await axios(config).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log("error in post save store visit: ", err)
        return null;
    })

    return res;
}

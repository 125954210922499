import "./WelcomePage.css";
import img1 from "../../assets/img1.jpg";
import "@fortawesome/fontawesome-free/css/all.css";
import { Link } from "react-router-dom";

const WelcomePage6 = () => {
  return (
    <div className="welcome-container">
      <div className="content-area">
        <div className="imageAndTextContainer">
          <div className="left-portion">
            <img src={img1} alt="img1" width={450} />
          </div>
          <div className="right-portion">
            <p className="manage_Store">Responsive Design</p>
            <p className="slide-text">
              Ensure a smooth experience on all devices by implementing a <br />
              responsive design for the filter interface.
            </p>
            <Link className="welcome-page-link-button" to="/theme-setup">
              <button className="button-next btn">Let's Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage6;

import { GET_FILTER_API_ERROR, RECIEVE_FILTER } from "../actions/getAllFilters";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const getAllFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIEVE_FILTER:
      return { ...state, loading: false, data: action.payload };
    case GET_FILTER_API_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getAllFilterReducer;

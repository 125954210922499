export const REQUEST_IMAGE_BY_PRODUCT = "REQUEST_IMAGE_BY_PRODUCT";
export const RECIEVE_IMAGE_BY_PRODUCT = "RECIEVE_IMAGE_BY_PRODUCT";
export const API_ERROR_IMAGE_BY_PRODUCT = "API_ERROR_IMAGE_BY_PRODUCT";

export const requestImageByPoduct = (productId) => ({
  type: REQUEST_IMAGE_BY_PRODUCT,
  payload: productId,
});

export const recieveImageByProduct = (image) => ({
  type: RECIEVE_IMAGE_BY_PRODUCT,
  payload: image,
});

export const apiError = (error) => ({
  type: API_ERROR_IMAGE_BY_PRODUCT,
  payload: error,
});

import "./Reusable.css";

const Loading = () => {
  return (
    <div className="loader-div">
      <span className="loader"></span>
      <label>We are fetching your data!</label>
    </div>
  );
};

export default Loading;

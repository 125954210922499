import { SketchPicker } from "react-color";
import "./FilterTree.css";
import reactCSS from "reactcss";
import Accordion from "react-bootstrap/Accordion";
import RangeSlider from "react-bootstrap-range-slider";

import { useState } from "react";

const FilterTree = () => {
  const [displayColor, setDisplayColor] = useState(false);
  const [displayFilterColor, setDisplayFilterColor] = useState(false);
  const [displayFilterBgColor, setDisplayFilterBgColor] = useState(false);
  const [displayFilterBorderColor, setDisplayFilterBorderColor] =
    useState(false);
  const [defaultValue, setValue] = useState(100);
  // eslint-disable-next-line no-unused-vars
  const [finalValue, setFinalValue] = useState(null);
  const storedColorString = localStorage.getItem("color");
  const storedTextColorString = localStorage.getItem("filterButtonColor");
  const storedBgColorString = localStorage.getItem("filterBgColor");
  const storedBorderColorString = localStorage.getItem("filterBorderColor");
  let storedColor;
  let storedTextColor;
  let storedBgColor;
  let storedBorderColor;

  try {
    storedColor = storedColorString ? JSON.parse(storedColorString) : null;
    storedTextColor = storedTextColorString
      ? JSON.parse(storedTextColorString)
      : null;
    storedBgColor = storedBgColorString
      ? JSON.parse(storedBgColorString)
      : null;
    storedBorderColor = storedBorderColorString
      ? JSON.parse(storedBorderColorString)
      : null;
  } catch (error) {
    console.error("Error parsing stored color:", error);
    storedColor = null;
    storedTextColor = null;
    storedBgColor = null;
    storedBorderColor = null;
  }

  const initialColor = storedColor
    ? storedColor
    : { r: "0", g: "0", b: "0", a: "1" };

  const initialTextColor = storedTextColor
    ? storedTextColor
    : { r: "0", g: "0", b: "0", a: "1" };

  const initialBgColor = storedBgColor
    ? storedBgColor
    : { r: "0", g: "0", b: "0", a: "1" };

  const initialBorderColor = storedBorderColor
    ? storedBorderColor
    : { r: "0", g: "0", b: "0", a: "1" };

  const [color, setColor] = useState(initialColor);
  const [filterTextColor, setFilterTextColor] = useState(initialTextColor);
  const [filterBgColor, setFilterBgColor] = useState(initialBgColor);
  const [filterBorderColor, setFilterBorderColor] =
    useState(initialBorderColor);
  const [font, setFont] = useState(localStorage.getItem("font"));
  const [fontWeight, setFontWeight] = useState(
    localStorage.getItem("fontWeight")
  );

  const handleClick = () => {
    setDisplayColor(!displayColor);
  };

  const handleFilterTextClick = () => {
    setDisplayFilterColor(!displayFilterColor);
  };

  const handleFilterBgClick = () => {
    setDisplayFilterBgColor(!displayFilterBgColor);
  };

  const handleFilterBorderClick = () => {
    setDisplayFilterBorderColor(!displayFilterBorderColor);
  };

  //!   const handleClose = () => {
  //!    setDisplayColor(false);
  //!   };

  const handleChange = (color) => {
    setColor(color.rgb);
    localStorage.setItem("color", JSON.stringify(color.rgb));
  };

  const handleFilterButtonChange = (filterTextColor) => {
    setFilterTextColor(filterTextColor.rgb);
    localStorage.setItem(
      "filterButtonColor",
      JSON.stringify(filterTextColor.rgb)
    );
  };

  const handleFilterButtonBgChange = (filterBgColor) => {
    setFilterBgColor(filterBgColor.rgb);
    localStorage.setItem("filterBgColor", JSON.stringify(filterBgColor.rgb));
  };

  const handleFilterBorderChange = (filterBorderColor) => {
    setFilterBorderColor(filterBorderColor.rgb);
    localStorage.setItem(
      "filterBorderColor",
      JSON.stringify(filterBorderColor.rgb)
    );
  };

  const handleFontChange = (defaultValue) => {
    setFont(defaultValue);
    localStorage.setItem("font", defaultValue);
  };

  const handleWeightChange = (defaultValue) => {
    setFontWeight(defaultValue);
    localStorage.setItem("fontWeight", defaultValue);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "40px",
        height: "10px",
        background: `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`,
        cursor: "pointer",
        padding: "20px 30px",
        marginTop: "10px",
        border: "5px solid white",
        borderRadius: "10px",
      },
      filterTextColor: {
        width: "40px",
        height: "10px",
        background: `rgba(${filterTextColor?.r}, ${filterTextColor?.g}, ${filterTextColor?.b}, ${filterTextColor?.a})`,
        cursor: "pointer",
        padding: "20px 30px",
        // marginTop: "10px",
        border: "5px solid white",
        borderRadius: "10px",
      },
      filterBgColor: {
        width: "40px",
        height: "10px",
        background: `rgba(${filterBgColor?.r}, ${filterBgColor?.g}, ${filterBgColor?.b}, ${filterBgColor?.a})`,
        cursor: "pointer",
        padding: "20px 30px",
        // marginTop: "10px",
        border: "5px solid white",
        borderRadius: "10px",
      },
      filterBorderColor: {
        width: "40px",
        height: "10px",
        background: `rgba(${filterBorderColor?.r}, ${filterBorderColor?.g}, ${filterBorderColor?.b}, ${filterBorderColor?.a})`,
        cursor: "pointer",
        padding: "20px 30px",
        // marginTop: "10px",
        border: "5px solid white",
        borderRadius: "10px",
      },
      swatch: {
        width: "30px",
        display: "flex",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
      },
      bg: {
        //? background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        color: `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`,
      },

      textBg: {
        //? background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        color: `rgba(${filterTextColor?.r}, ${filterTextColor?.g}, ${filterTextColor?.b}, ${filterTextColor?.a})`,
      },

      filterBg: {
        //? background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        background: `rgba(${filterBgColor?.r}, ${filterBgColor?.g}, ${filterBgColor?.b}, ${filterBgColor?.a})`,
      },

      filterBorder: {
        //? background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        border: `1px solid rgba(${filterBorderColor?.r}, ${filterBorderColor?.g}, ${filterBorderColor?.b}, ${filterBorderColor?.a})`,
      },
      rgba: {
        background: "white",
        marginTop: "20px",
        padding: "10",
        fontSize: "18px",
      },
      btn: {
        cursor: "pointer",
        padding: "10px 20px",
        marginTop: "20px",
        border: "none",
        borderRadius: "5px",
        fontSize: "18px",
      },
      fontSizeLabel: {
        width: "50%",
      },
      fontWeightLabel: {
        width: "50%",
        marginLeft: "40px",
      },
    },
  });

  const weight = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
  ];
  return (
    <div className="d-flex flex-sm-row">
      <div className="mt-4x ml-4 mr-7">
        <p className="h6 ml-0">Layout</p>
        <div className="d-flex flex-column">
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio1"
            />
            <label className="form-check-label label" htmlFor="flexRadio1">
              Horizontal
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio2"
            />
            <label className="form-check-label label" htmlFor="flexRadio2">
              Vertical
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio3"
            />
            <label
              className="form-check-label label"
              htmlFor="flexRadioDefault3"
            >
              Off-Canvas
            </label>
          </div>

          <div className="form-check mt-3">
            <input
              className="form-check-input border-black"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label label"
              htmlFor="flexCheckDefault"
            >
              I want to customize the filter tree template
            </label>
          </div>
        </div>
        <p className="h6 ml-0 mt-4">General</p>
        <div className="d-flex flex-column">
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
            />
            <label className="form-check-label label" htmlFor="flexRadio2">
              Show Refine By block
            </label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input border-black"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label label"
              htmlFor="flexRadioDefault3"
            >
              Show the number of matching products
            </label>
          </div>

          <div className="form-check mt-2">
            <input
              className="form-check-input border-black"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label label"
              htmlFor="flexCheckDefault"
            >
              Hide filter options when only one defaultValue
            </label>
          </div>
        </div>
        <p className="h6 ml-0 mt-4">Filter Option</p>
        <div style={styles.bg}>
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
            {displayColor ? (
              <div style={styles.popover}>
                <div style={styles.cover} />
                <SketchPicker color={color} onChange={handleChange} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column relative gap-5 d-lg-flex flex-lg-row">
          <div className="d-flex flex-column d-lg-flex flex-lg-column">
            <p className="h6 ml-0 mt-4">Font size</p>
            <input
              defaultValue={font}
              onChange={(e) => handleFontChange(e.target.defaultValue)}
              type="text"
              className="font-input"
              id="basic-url"
              aria-describedby="basic-addon3 basic-addon4"
            />
          </div>
          <div className="d-flex flex-column d-lg-flex flex-lg-column">
            <p className="h6 ml-0 mt-lg-4">Font size</p>
            <select
              className="font-weight-select"
              aria-label="Default select example"
              defaultValue={fontWeight}
              onChange={(e) => handleWeightChange(e.target.defaultValue)}
            >
              <option>Open this select menu</option>
              {/* <option selected>Open this select menu</option> */}
              {weight.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <p className="h6 ml-0 mt-4">Text Transform</p>
        <div className="d-flex flex-column">
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio1"
            />
            <label className="form-check-label label" htmlFor="flexRadio1">
              None
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio2"
            />
            <label className="form-check-label label" htmlFor="flexRadio2">
              Capitalize
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio3"
            />
            <label
              className="form-check-label label"
              htmlFor="flexRadioDefault3"
            >
              Uppercase
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input border-black"
              type="radio"
              name="flexRadioDefault"
              id="flexRadio3"
            />
            <label
              className="form-check-label label"
              htmlFor="flexRadioDefault3"
            >
              Lowercase
            </label>
          </div>
        </div>
        <hr />
        <p className="h6 ml-0 mt-4">Filter Button</p>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-center align-items-center gap-5">
            <div style={styles.bg}>
              <div style={styles.swatch} onClick={handleFilterTextClick}>
                <div style={styles.filterTextColor} />
                {displayFilterColor ? (
                  <div style={styles.popover}>
                    <div style={styles.cover} />
                    <SketchPicker
                      color={filterTextColor}
                      onChange={handleFilterButtonChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="">Text color</div>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-5">
            <div style={styles.bg}>
              <div style={styles.swatch} onClick={handleFilterBgClick}>
                <div style={styles.filterBgColor} />
                {displayFilterBgColor ? (
                  <div style={styles.popover}>
                    <div style={styles.cover} />
                    <SketchPicker
                      color={filterBgColor}
                      onChange={handleFilterButtonBgChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="">Background Color</div>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-3">
          <div className="d-flex justify-content-center align-items-center gap-5">
            <div style={styles.bg}>
              <div style={styles.swatch} onClick={handleFilterBorderClick}>
                <div style={styles.filterBorderColor} />
                {displayFilterBorderColor ? (
                  <div style={styles.popover}>
                    <div style={styles.cover} />
                    <SketchPicker
                      color={filterBorderColor}
                      onChange={handleFilterBorderChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="">Border Color</div>
          </div>
        </div>
      </div>
      <div className="mt-4x" style={{ width: "25%" }}>
        <p className="h6 ml-0">Vertical layout preview</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            className="ml-0 pointer"
            style={{
              color: `rgba(${storedColor?.r}, ${storedColor?.g}, ${storedColor?.b}, ${storedColor?.a})`,
              fontSize: `${font}px`,
              fontWeight: `${fontWeight}`,
            }}
          >
            Filter By
          </span>
          <div className="clear-button">clear all</div>
        </div>
        <div style={{ display: "flex", fontSize: "15px" }}>
          <span style={{ marginRight: "5px" }}>Vendor: </span>
          <span style={{ fontWeight: "bold" }}>Bush Smarts</span>
        </div>
        <div style={{ display: "flex", fontSize: "15px" }}>
          <span style={{ marginRight: "5px" }}>Vendor: </span>
          <span style={{ fontWeight: "bold" }}>Bush Smarts</span>
        </div>
        <hr />
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
              }}
            >
              <span
                style={{
                  color: `rgba(${storedColor?.r}, ${storedColor?.g}, ${storedColor?.b}, ${storedColor?.a})`,
                  fontSize: `${font}px`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                Collection
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion>
                <Accordion.Item style={{ fontSize: "3px" }} eventKey="2">
                  <Accordion.Header
                    style={{
                      marginLeft: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <span style={{ fontSize: "15px" }}>Fashion</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ fontSize: "15px" }}>
                    Jacket
                  </Accordion.Body>
                  <Accordion.Body style={{ fontSize: "15px" }}>
                    Jacket
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion>
                <Accordion.Item style={{ fontSize: "3px" }} eventKey="3">
                  <Accordion.Header
                    style={{
                      marginLeft: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <span style={{ fontSize: "15px" }}>Accessories</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ fontSize: "15px" }}>
                    Hats
                  </Accordion.Body>
                  <Accordion.Body style={{ fontSize: "15px" }}>
                    Bags
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header style={{ marginLeft: "0px", paddingLeft: "0px" }}>
              <span
                style={{
                  color: `rgba(${storedColor?.r}, ${storedColor?.g}, ${storedColor?.b}, ${storedColor?.a})`,
                  fontSize: `${font}px`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                Vendor
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <div className="form-check">
                <input
                  className="form-check-input border-black"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label label" htmlFor="flexRadio2">
                  Bush Smarts
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input border-black"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label label" htmlFor="flexRadio2">
                  Red Wing
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input border-black"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label label" htmlFor="flexRadio2">
                  Snow Peak
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input border-black"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label label" htmlFor="flexRadio2">
                  Ursa Major
                </label>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item eventKey="4">
            <Accordion.Header style={{ marginLeft: "0px", paddingLeft: "0px" }}>
              <span
                style={{
                  color: `rgba(${storedColor?.r}, ${storedColor?.g}, ${storedColor?.b}, ${storedColor?.a})`,
                  fontSize: `${font}px`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                Price
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <RangeSlider
                max={1000}
                defaultValue={defaultValue}
                onChange={(e) => setValue(e.target.defaultValue)}
                onAfterChange={(e) => setFinalValue(e.target.defaultValue)}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item eventKey="4">
            <Accordion.Header style={{ marginLeft: "0px", paddingLeft: "0px" }}>
              <span
                style={{
                  color: `rgba(${storedColor?.r}, ${storedColor?.g}, ${storedColor?.b}, ${storedColor?.a})`,
                  fontSize: `${font}px`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                Rating
              </span>
            </Accordion.Header>
            <Accordion.Body
              className="d-flex flex-column"
              style={{
                fontSize: "20px",
                gap: "3px",
                color: "#ff9800",
                fontWeight: "600",
              }}
            >
              <span>☆☆☆☆☆</span>
              <span>☆☆☆☆☆</span>
              <span>☆☆☆☆☆</span>
              <span>☆☆☆☆☆</span>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr className="mt-sm-2 mt-lg-5 mb-5" />
        <span>Filter Button Preview</span>
        <div
          style={{
            cursor: "pointer",
            marginTop: "10px",
            padding: "10px",
            background: `rgba(${storedBgColor?.r}, ${storedBgColor?.g}, ${storedBgColor?.b}, ${storedBgColor?.a})`,
            border: `1px solid rgba(${storedBorderColor?.r}, ${storedBorderColor?.g}, ${storedBorderColor?.b}, ${storedBorderColor?.a})`,
            color: `rgba(${storedTextColor?.r}, ${storedTextColor?.g}, ${storedTextColor?.b}, ${storedTextColor?.a})`,
            fontSize: `${font}px`,
            fontWeight: `${fontWeight}`,
          }}
        >
          {" "}
          Filter By
        </div>
      </div>
    </div>
  );
};

export default FilterTree;

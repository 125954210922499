/* eslint-disable jsx-a11y/anchor-is-valid */
// import Sidebar from "../Sidebar";
import "./ManageFilter.css";

import { useEffect, useState } from "react";
import CustomPagerDropdown from "../../components/CustomPagerDropdown/CustomPagerDropdown";
import { requestFilter } from "../../redux/actions/getAllFilters";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../reusables/Pagination";
import { requestDeleteFilter } from "../../redux/actions/deleteFilterActions";
import { requestEditFilter } from "../../redux/actions/editFilter";
import Loading from "../../reusables/Loading";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import Spinner from "react-bootstrap/Spinner";
import { Switch } from "../../components/Switch/Switch";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../components/DeleteModal/DeleteModal";

const filterTableHeader = ["Name", "Collection", "Status", "Actions"];

const ManageFilter = ({ fetchFilter, error }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPageOptions = [5, 10, 20];
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [showDiv, setShowDiv] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [divId, setDivId] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const filtersData = useSelector((state) => state.getAllFilters.data);

  const deleteFilter = (id) => {
    dispatch(requestDeleteFilter(id));
  };

  const editFilter = (id) => {
    dispatch(requestEditFilter(id));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!filtersData?.data) {
          setLoading(true);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(true);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filtersData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = (rowId) => {
    if (rowId) {
      setShowDiv((prevShowDiv) =>
        prevShowDiv && divId === rowId ? !prevShowDiv : true
      );
      setDivId(rowId);
    }
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
  };

  const handleDelete = (id) => {
    setDeleteModalShow(true);
    console.log("delete: " + id);
  };

  return (
    <div className="content-area">
      <div className="headerContainer">
        <h5>
          <b>Manage Filters</b>
        </h5>
        <div
          onClick={() => navigate("?p=add-filter")}
          className="addFilterButton"
        >
          Add Filter Set
        </div>
      </div>
      <div>
        {loading ? (
          <div className="loaderContainer">
            <Spinner animation="border" size="md" variant="primary" />
          </div>
        ) : (
          <div className="table-container">
            {/* <div
              className="d-flex justify-content-between align-items-center"
              style={{ borderBottom: "1px solid rgba(226, 226, 226, 1)" }}
            >
              <CustomPagerDropdown
                itemsPerPageOptions={itemsPerPageOptions}
                onItemsPerPageChange={handleItemsPerPageChange}
                heading="Show: "
              />
              <span className="d-flex gap-2 align-items-center justify-content-end mr-2">
                <label>Search</label>
                <input
                  className="search-input"
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </span>
            </div> */}
            <h5 className="mb-3">Filter Set List</h5>
            <table className="productTable">
              <thead>
                {filterTableHeader.map((item, index) => (
                  <th key={index} className="table-header-title">
                    {item}
                  </th>
                ))}
              </thead>
              <tbody>
                {filtersData?.data
                  ?.filter((row) =>
                    Object.values(row).some((value) => {
                      if (typeof value === "number") {
                        return value === parseInt(searchQuery, 10);
                      } else {
                        return String(value)
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      }
                    })
                  )
                  .map((row) => (
                    <tr key={row.id}>
                      <td className="table-data">{row.filter_key}</td>
                      <td className="table-data">{row.filter_lable}</td>
                      <td className="table-data">
                        <Switch
                          isToggled={isToggled}
                          onToggle={() => setIsToggled(!isToggled)}
                        />
                      </td>
                      <td className="table-data">
                        <ActionIcons onDelete={() => handleDelete(9)} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {loading ? (
        <Pagination
          disabled={true}
          itemsPerPage={itemsPerPage}
          requestData={requestFilter}
          data={filtersData}
        />
      ) : (
        <Pagination
          itemsPerPage={itemsPerPage}
          requestData={requestFilter}
          data={filtersData}
        />
      )}
      {/* delete filter modal */}
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        headerText="Delete filter set?"
        bodyText="Do you want to delete your filter set? This can't be undone"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filter: state.getAllFilters.data,
  error: state.getAllFilters.error,
  loading: state.getAllFilters.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFilter: () => dispatch(requestFilter()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageFilter);

import axios from "axios";
import { headers } from "../redux/sagas/header";
import { getItem } from "../utils"

const shopId = getItem("shopId")
export const integrateThemeStatus = async () => {

    var data = JSON.stringify({ "theme": getItem("selectedThemeId") });

    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_FRONTEND_URL}/api/theme/status/${shopId}`,
        headers: headers,
        data: data
    };

    const res = await axios(config)
        .then((res) => {
            if (!res.data) {
                return null;
            }

            return res.data.Theme_status;
        })
        .catch((err) => {
            console.log("integrateThemeStatus error: ", err)
            return null;
        });

    return res;
}

export const getSynchDateTime = async () => {

    const data = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}/api/syncdatetime/${shopId}`, { headers: headers }).then((data) => {
        return data.data.syncdatetime;
    }).catch((err) => {
        console.log("getSynchDateTime error: ", err)
        return null;
    })

    return data;
}
import { put, call, takeLatest } from "redux-saga/effects";

import axios from "axios";
import {
  REQUEST_VARIANT,
  apiError,
  recieveVariant,
} from "../actions/variantActions";
import { headers } from "./header";

function* fetchVariants() {
  try {
    const response = yield call(
      axios.get,
      "https://metaapp-api.dealopia.com/api/variants/?page=1",
      { headers: headers }
    );
    yield put(recieveVariant(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchFetchVariants() {
  yield takeLatest(REQUEST_VARIANT, fetchVariants);
}

export default watchFetchVariants;

// import Sidebar from "../Sidebar";
import "./Settings.css";

import { useState } from "react";

import CustomPagerDropdown from "../CustomPagerDropdown/CustomPagerDropdown";
import { dummyData } from "./datasource";

const Settings = () => {
  // const itemsPerPageOptions = [5, 10, 20];
  const itemsPerPageOptions = [5, 10, 20];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.defaultValue);
  };

  const handleButtonClick = (rowId) => {
    // Handle button click for a specific row (you can implement your logic here)
    console.log(`Button clicked for row with ID: ${rowId}`);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset the current page when changing items per page
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = dummyData.slice(startIndex, endIndex);
  return (
    <div className="product-container">
      <p className="heading">Settings</p>
      <div>
        <div className="filter-pagination">
          <CustomPagerDropdown
            itemsPerPageOptions={itemsPerPageOptions}
            onItemsPerPageChange={handleItemsPerPageChange}
            heading="Show "
          />
          <button className="add-filter-button">Add New settings</button>
        </div>
        <input
          className="search-input-filter"
          type="text"
          placeholder="Search here"
          defaultValue={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="table-container">
          <table className="table2">
            <thead>
              <tr>
                <th>Filter Field</th>
                <th>Filter Lable</th>
                <th>Input Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData
                .filter((row) =>
                  Object.values(row).some((defaultValue) => {
                    if (typeof defaultValue === "number") {
                      // Handle numbers (such as the price and variants)
                      return defaultValue === parseInt(searchQuery, 10);
                    } else {
                      // Convert non-number values to strings
                      return String(defaultValue)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                    }
                  })
                )
                .map((row) => (
                  <tr key={row.Title}>
                    <td className="table-data">{row.Filter_Field}</td>
                    <td className="table-data">{row.Filter_Lable}</td>
                    <td className="table-data">{row.Input_Type}</td>
                    <td className="table-data">
                      {/* Dynamic button for each row */}
                      <button
                        id="button"
                        onClick={() => handleButtonClick(row.Title)}
                      >
                        View/edit/delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Settings;

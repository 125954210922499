import "./WelcomePage.css";
import slide1 from "../../assets/slide1.jpg";
import { Link } from "react-router-dom";

const WelcomePage5 = () => {
  return (
    <div className="welcome-container">
      <div className="content-area">
        <div className="imageAndTextContainer">
          <div className="left-portion">
            <img src={slide1} alt="slide1" width={450} />
          </div>
          <div className="right-portion">
            <p className="manage_Store">Filter Display Options</p>
            <p className="slide-text">
              Customize filter displays on the storefront, choosing between{" "}
              <br />
              sidebar, top-bar and off-canvas options, and match styles to your{" "}
              <br />
              store's theme for a cohesive look.
            </p>
            <Link className="welcome-page-link-button" to="/theme-setup">
              <button className="button-next btn">Let's Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage5;

import {
  API_ERROR_IMAGE_BY_PRODUCT,
  RECIEVE_IMAGE_BY_PRODUCT,
} from "../actions/imageByProduct";

const initialState = {
  data: [],
  error: null,
};

const imageByProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIEVE_IMAGE_BY_PRODUCT:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case API_ERROR_IMAGE_BY_PRODUCT:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default imageByProductReducer;

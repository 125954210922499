// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid gray;
  border-radius: 8px;
  max-width: 90px;
  cursor: pointer;
}

.icon-1-2 {
  border-right: 1px solid gray;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-3 {
  background: rgb(226, 70, 70);
  padding: 6px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionIcons/ActionIcons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".item-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  border: 1px solid gray;\n  border-radius: 8px;\n  max-width: 90px;\n  cursor: pointer;\n}\n\n.icon-1-2 {\n  border-right: 1px solid gray;\n  padding: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.icon-3 {\n  background: rgb(226, 70, 70);\n  padding: 6px;\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

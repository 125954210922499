import "./WelcomePage.css";
import img4 from "../../assets/img4.jpg";
import { Link } from "react-router-dom";

const WelcomePage3 = () => {
  return (
    <div className="welcome-container">
      <div className="content-area">
        <div className="imageAndTextContainer">
          <div className="left-portion">
            <img src={img4} alt="img4" width={450} />
          </div>
          <div className="right-portion">
            <p className="manage_Store">Multi-Level Filtering</p>
            <p className="slide-text">
              Enable multi-level filtering for intricate product catalogs,
              allowing <br /> customers to narrow down searches effectively.
            </p>
            <Link className="welcome-page-link-button" to="/theme-setup">
              <button className="button-next btn">Let's Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage3;

import edit from "../../assets/action-icons/Vector.svg";
import remove from "../../assets/action-icons/trash-2.svg";
import { TooltipItem } from "../Tooltip/Tooltip";

export const FilterOptionItem = ({ onEdit, onDelete }) => {
  return (
    <div className="tableListItem tableListItemText">
      <div className="labelItem">
        <div className="labelItemContainer">Collection</div>
      </div>
      <div className="sourceItem">Collection</div>
      <div className="displayItem">Checkbox</div>
      <div className="actionItem">
        <div className="action-item-container">
          <TooltipItem title="Edit Filter option" id="1">
            <img onClick={onEdit} className="icon-1-2" src={edit} alt="edit" />
          </TooltipItem>
          <TooltipItem title="Delete Filter option" id="1">
            <img
              onClick={onDelete}
              className="icon-3"
              src={remove}
              alt="remove"
            />
          </TooltipItem>
        </div>
      </div>
    </div>
  );
};

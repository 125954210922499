import { useRef, useState, useEffect } from "react";
import dashboard from "../../assets/dashboard.svg";
import { Link, useLocation } from "react-router-dom";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { Truncate } from "@shopify/polaris";
import { getItem } from "../../utils";
import { uninstallApp } from "../../services/uninstallService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

const Navbar = ({ onMenuClick }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isNavbarVisible, setNavbarVisibility] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const navref = useRef(null);

  const NavbarMenus = [
    { route: "dashboard", label: "Dashboard", icons: dashboard },
    { route: "filters", label: "Filters", icons: dashboard },
    // { route: "orders", label: "Orders", icons: dashboard },
    // { route: "products", label: "Products", icons: dashboard },
    { route: "theme-setup", label: "Theme Setup", icons: dashboard },
    { route: "more", label: "More", icons: dashboard },
    { route: "help", label: "Help", icons: dashboard },
    // { route: "cron-logs", label: "Cron Logs", icons: dashboard },
    // { route: "webhook-logs", label: "Webhook logs", icons: dashboard },
    // { route: "logout", label: "Logout", icons: dashboard },
    // Add more menu items as needed
  ];

  const NavbarSubmenus = {
    filters: [
      { route: "manage-filters", label: "Manage Filters", icons: dashboard },
      { route: "Metafields", label: "Metafields", icons: dashboard },
      // { route: "filter-tree", label: "Filter tree", icons: dashboard },
      // {
      //   route: "custom-range-slider",
      //   label: "Custom Range Slider",
      //   icons: dashboard,
      // },
    ],
    more: [
      // { route: "color-swatch", label: "Color Swatch", icons: dashboard },
      { route: "merge-values", label: "Merge values", icons: dashboard },
      { route: "filter-layout", label: "Filter layout", icons: dashboard },
      { route: "Pricing", label: "Pricing", icons: dashboard },
      { route: "test-search", label: "Test Your Search", icons: dashboard },
      // { route: "integration", label: "Integration", icons: dashboard },
    ],
    help: [
      { route: "documentation", label: "Documentation", icons: dashboard },
      { route: "contact", label: "Contact Us", icons: dashboard },
    ],
  };

  const { search } = useLocation();
  const current_url = search.split("=")[1];

  const handleDropdownToggle = (index) => {
    const dropdown = navref.current.querySelector(`#submenu-${index}`);
    if (dropdown) {
      dropdown.classList.toggle("show");
    }
  };

  const handleMenuClick = (route, index) => {
    onMenuClick(route);

    // Close the previously opened dropdown
    if (openDropdown !== null && openDropdown !== index) {
      handleDropdownToggle(openDropdown);
      setOpenDropdown(null);
    }

    // Toggle the clicked dropdown
    handleDropdownToggle(index);

    // Update the openDropdown state
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleHover = (index) => {
    // Open the dropdown on hover
    handleDropdownToggle(index);

    // Update the openDropdown state
    setOpenDropdown(index);
  };

  const handleMouseLeave = () => {
    // Close the dropdown on mouse leave
    if (openDropdown !== null) {
      handleDropdownToggle(openDropdown);
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen size is less than or equal to 992 pixels (adjust as needed)
      const isMobile = window.innerWidth <= 992;

      // Set the visibility state based on the screen size
      setNavbarVisibility(!isMobile);
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Call the handleResize function initially
    handleResize();

    // Remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUninstall = () => {
    setDeleteModalShow(true);
  };

  const handleUninstallApp = async () => {
    setLoading(true);
    const shopId = getItem("shopId");
    setDeleteModalShow(false);
    const res = await uninstallApp(shopId);

    if (res === null) {
      toast.error("Something went wrong. Please try again later");
      setLoading(false);
      return;
    }
    setLoading(false);
    const shop = getItem("shop").split(".")[0];
    // redirect to shopify store after successful un-installation
    window.location.href = `https://admin.shopify.com/store/${shop}`;
    return;
  };

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <nav
        className={`navbar navbar-expand-lg navbar-bg ${
          isNavbarVisible ? "d-flex flex-column p-0" : ""
        }`}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body navbar-bg content-area">
              <ul
                className={`navbar-nav justify-content-start flex-grow-1 d-flex flex-column ${
                  isNavbarVisible ? "d-flex flex-column flex-lg-row" : ""
                }`}
                ref={navref}
                onMouseLeave={handleMouseLeave}
              >
                {NavbarMenus.map((item, index) => (
                  <li
                    key={index}
                    className={` p-0 d-flex gap-2 ${
                      NavbarSubmenus[item.route] ? "dropdown" : ""
                    }`}
                    onMouseEnter={() => isNavbarVisible && handleHover(index)}
                  >
                    <Link
                      to={item.route}
                      className={`nav-link ${
                        item.route === current_url ? "active" : ""
                      } ${NavbarSubmenus[item.route] ? "dropdown-toggle" : ""}`}
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleMenuClick(item.route, index);
                      }}
                    >
                      {item.label}
                    </Link>
                    {NavbarSubmenus[item.route] && (
                      <ul
                        id={`submenu-${index}`}
                        className={`dropdown-menu p-0 ${
                          openDropdown === index ? "show" : ""
                        }`}
                      >
                        {NavbarSubmenus[item.route].map(
                          (submenuItem, submenuIndex) => (
                            <li key={submenuIndex}>
                              <hr style={{ marginTop: "-1px" }} />
                              <Link
                                to={`?p=${submenuItem.route}`}
                                className="dropdown-item"
                                onClick={() =>
                                  handleMenuClick(submenuItem.route, index)
                                }
                              >
                                {submenuItem.label}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
              <ul>
                <li className="p-0 d-flex gap-2" onClick={handleUninstall}>
                  <a
                    className="navbar-nav nav-link"
                    style={{ color: "#9F9F9F" }}
                    role="button"
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        variant="secondary"
                      />
                    ) : (
                      "Uninstall App"
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={() => handleUninstallApp()}
        headerText="Are you sure to uninstall the app?"
        bodyText="You can re-install from shopify app store or apps and sales channels"
      />
    </>
  );
};

export default Navbar;

import "./DeleteModal.css";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../assets/close.png";

export const DeleteModal = ({
  show,
  onHide,
  headerText,
  bodyText,
  onDelete,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modalContainer">
        <div className="ModalHeaderContainer">
          <h6>{headerText}</h6>
          <img
            onClick={onHide}
            className="closeIcon"
            src={closeIcon}
            alt="close"
          />
        </div>
        <div className="body">{bodyText}</div>
        <div className="deleteFooter">
          <div onClick={onHide} className="modalCancelBtn">
            Cancel
          </div>
          <div onClick={onDelete} className="modalDeleteBtn">
            Delete
          </div>
        </div>
      </div>
    </Modal>
  );
};

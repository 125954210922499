export const REQUEST_VARIANT_BY_PRODUCT = "REQUEST_VARIANT_BY_PRODUCT";
export const RECEIVE_VARIANT_BY_PRODUCT = "RECEIVE_VARIANT_BY_PRODUCT";
export const API_ERROR_VARIANT_BY_PRODUCT = "API_ERROR_VARIANT_BY_PRODUCT";

export const requestVariantsByProduct = (id) => ({
  type: REQUEST_VARIANT_BY_PRODUCT,
  payload: id
});

export const receiveVariantByProduct = (productVariants) => ({
    type: RECEIVE_VARIANT_BY_PRODUCT,
    payload: productVariants
})

export const apiError = (error) => ({
    type: API_ERROR_VARIANT_BY_PRODUCT,
    payload: error,
})

import axios from "axios";
import { headers } from "../redux/sagas/header";
import { getItem } from "../utils";

export const productSync = async () => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/sync?shop_domain=${getItem("shop")}&token=${getItem("token")}`).then((res) => {

        if (!res.data) {
            return null;
        }

        return res.data;
    }).catch((err) => {
        console.log("product sync error: ", err)
        return null;
    })

    return data;
}
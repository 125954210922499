import React, { useEffect, useState } from "react";
import image from "../../assets/data-sync.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import ThemeSetupFinish from "./ThemeSetupFinish";
import { productSync } from "../../services";
import "./ThemeSetup.css";

const ThemeSetupSyncPage = () => {
  const [progress, setProgress] = useState(0);
  const [theme, setTheme] = useState("");
  const [features, setFeatures] = useState("");

  useEffect(() => {
    const fillWidth = () => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        return newProgress <= 97 ? newProgress : 97;
      });
    };

    return async () => {
      // Adjust the interval based on your desired animation smoothness
      const interval = setInterval(fillWidth, 150);

      const data = await productSync();

      if (data === null) {
        clearInterval(interval);
        toast.error("Something went wrong. Please try again later");
        return;
      }

      const themeRes = JSON.parse(data["Theme name"].replace(/'/g, '"'));
      setTheme(themeRes.theme_name);
      setFeatures(themeRes.features.join(", "));
      clearInterval(interval);
      setProgress(98);
      return;
    };
  }, []);

  // Calculate estimated time based on progress (assuming linear progress)
  const estimatedTime = (100 - progress) * 0.02;
  return (
    <div
      className="content-area"
      style={{
        paddingTop: "60px",
      }}
    >
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      {progress !== 98 ? (
        <div
          className="d-flex"
          style={{
            background: "#ffffff",
            // padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <div className="left-portion" style={{ paddingLeft: "20px" }}>
            <div>
              <img
                style={{
                  height: "30rem",
                  width: "90%",
                  // display: "flex",
                  // borderRadius: "8px 0px 0px 8px",
                }}
                src={image}
                alt="data-sync"
              />
            </div>
          </div>
          <div className="right-portion" style={{ paddingRight: "20px" }}>
            <p className="theme-sync-heading">Getting ready ...</p>
            <p className="theme-sync-content" style={{ fontSize: "14px" }}>
              Please wait while we are running the theme setup and syncing data{" "}
              <br />
              for your store. First time sync may take a bit longer than regular{" "}
              <br />
              sync.
            </p>
            <div className="sync">
              <i className="fa fa-sync" />
              <p className="theme-sync_text">Sync status</p>
            </div>
            <ProgressBar
              style={{
                height: "10px",
                width: "100%",
              }}
              now={progress}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p style={{ marginLeft: "0", fontSize: "14px" }}>
                Estimated time to complete: {estimatedTime.toFixed(2)} minutes
              </p>
              <p>{progress}%</p>
            </div>
          </div>
        </div>
      ) : (
        <ThemeSetupFinish theme={theme} features={features} />
      )}
    </div>
  );
};

export default ThemeSetupSyncPage;

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const API_ERROR = 'API_ERROR';

export const requestProducts = (currentPage, itemsPerPage) => ({
  type: REQUEST_PRODUCTS,
  payload: {currentPage, itemsPerPage},
});

export const receiveProducts = (products) => ({
  type: RECEIVE_PRODUCTS,
  payload: products,
});

export const apiError = (error) => ({
  type: API_ERROR,
  payload: error,
});
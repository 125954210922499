import "./FiltersCSS.css";
import filter from "../../../../assets/sliders.svg";


const handleNavigate = () => {
  const url =  "/main?p=manage-filters";
  window.open(url, '_blank');
};

const Filters = () => {
  return (
    <div className="filters">
      <h4 className="dashboard-component-heading ">Filters</h4>
      <p className="dashboard-component-content">
        Filter Setup, Filter options and many more
      </p>
      <button
        className="dashboard-component-button"
        style={{ marginTop: "30px" }} onClick={handleNavigate}
      >
        <span className="d-flex gap-2">
          <img src={filter} alt="layout.svg" />
          Manage filters
        </span>
      </button>
    </div>
  );
};

export default Filters;

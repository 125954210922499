export const REQUEST_VARIANT = 'REQUEST_VARIANT'
export const RECIEVE_VARIANT = 'RECIEVE_VARIANT'
export const VARIANT_API_ERROR = 'VARIANT_API_ERROR '

export const requestVariant = () => ({
    type: REQUEST_VARIANT
})

export const recieveVariant = (variants) => ({
    type: RECIEVE_VARIANT,
    payload: variants,
})

export const apiError = (error) => ({
    type: VARIANT_API_ERROR,
    payload: error,
})
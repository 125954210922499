import Filters from "../DashboardPage/DashboardContent/Filters/Filters";
import PlanStatus from "../DashboardPage/DashboardContent/PlanStatus/PlanStatus";
import SyncStore from "../DashboardPage/DashboardContent/SyncStore/SyncStore";
import ThemeStatus from "../DashboardPage/DashboardContent/ThemeStatus/ThemeStatus";
// import Sidebar from "../Sidebar";
import "./DashboardPage.css";

const Dashboard = () => {
  return (
    <div className="dashboard-container" style={{ paddingTop: "20px" }}>
      <div className="content-area">
        <span className="title-header">Dashboard</span>
        <div
          className="d-flex justify-content-between"
          style={{ paddingTop: "20px" }}
        >
          <div className="col col-8 pl-0 pr-0">
            <ThemeStatus />
          </div>
          <div className="col col-4 pr-0">
            <Filters />
          </div>
        </div>
        <div className="d-flex" style={{ paddingTop: "20px" }}>
          <div className="col col-4 pl-0 pr-0">
            <PlanStatus />
          </div>
          <div className="col col-8 pr-0">
            <SyncStore />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

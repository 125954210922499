import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Addfilter.css";
import Form from "react-bootstrap/Form";
import closeIcon from "../../assets/close.png";
import addIcon from "../../assets/plus.png";
import { FilterOptionItem } from "../../components/FilterOptionItem/FilterOptionItem";
import { AddEditFilterOptionModal } from "../../components/FilterOptionModal/AddEditFilterOption";
import { DeleteModal } from "../../components/DeleteModal/DeleteModal";

const Addfilter = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleEdit = (id) => {
    setShow(true);
  };

  const handleDelete = (id) => {
    setShowDelete(true);
  };

  return (
    <div className="content-area">
      <div className="addFilterHeaderContainer my-4">
        <div className="headerLeftContainer">
          <div onClick={() => navigate(-1)} className="backIconContainer">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
          <h5 className="addFilterHeaderText">
            <b>Add Filter Set</b>
          </h5>
        </div>
        <div className="saveBtn filterSaveBtn">Save</div>
      </div>
      <div className="filterSetContainer">
        <h5>Filter Set</h5>
        <Form.Label htmlFor="name">Name</Form.Label>
        <Form.Control type="text" id="name" name="name" className="mb-2" />
        <Form.Label htmlFor="appliesTo">Applies To</Form.Label>
        <Form.Control
          placeholder="Search for collections"
          type="text"
          id="appliesTo"
        />
        <div className="collectionContainer">
          <div className="collection">
            <p className="collectionText">All Collections</p>
            <img className="closeIcon" src={closeIcon} alt="Close" />
          </div>
          <div className="collection">
            <p className="collectionText">Search Page</p>
            <img className="closeIcon" src={closeIcon} alt="Close" />
          </div>
          <div className="collection">
            <p className="collectionText">All Products</p>
            <img className="closeIcon" src={closeIcon} alt="Close" />
          </div>
        </div>
      </div>
      <div className="filterSetContainer">
        <div className="filterTableHeader">
          <h6 className="listText">Filter option</h6>
          <div onClick={() => setShow(true)} className="addFilterBtnContainer">
            <img className="addFilterOptionIcon" src={addIcon} alt="add" />
            <p className="addFilterOptionText">Add filter option</p>
          </div>
        </div>
        <div className="tableListContainer">
          <div className="tableListHeader tableListHeaderText">
            <div className="labelItem">Label</div>
            <div className="sourceItem">Source</div>
            <div className="displayItem">Display type</div>
            <div className="actionItem">Actions</div>
          </div>
          {/* table list item */}
          <FilterOptionItem
            onEdit={() => handleEdit(6)}
            onDelete={() => handleDelete(7)}
          />
          <FilterOptionItem
            onEdit={() => handleEdit(6)}
            onDelete={() => handleDelete(7)}
          />
          <FilterOptionItem
            onEdit={() => handleEdit(6)}
            onDelete={() => handleDelete(7)}
          />
        </div>
      </div>
      {/* add or edit filter modal */}
      <AddEditFilterOptionModal show={show} onHide={() => setShow(false)} />
      {/* delete filter modal */}
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        headerText="Delete filter option?"
        bodyText="Do you want to delete this filter option? This can't be undone"
      />
    </div>
  );
};

export default Addfilter;

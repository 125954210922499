import {
  EDIT_FILTER_API_ERROR,
  RECIEVE_EDIT_FILTER,
} from "../actions/editFilter";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const editFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIEVE_EDIT_FILTER:
      return { ...state, loading: false, data: action.payload };
    case EDIT_FILTER_API_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default editFilterReducer;

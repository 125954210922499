export const ADD_NEW_FILTER_REQUEST = "ADD_NEW_FILTER_REQUEST";
export const ADD_NEW_FILTER_RESPONSE = "ADD_NEW_FILTER_RESPONSE";
export const ADD_NEW_FILTER_API_ERROR = "ADD_NEW_FILTER_API_ERROR";

export const addNewFilterRequest = (data) => ({
  type: ADD_NEW_FILTER_REQUEST,
  payload: data,
});

export const addNewFilterResponse = (response) => ({
  type: ADD_NEW_FILTER_RESPONSE,
  payload: response,
});

export const addNewFilterApiError = (error) => ({
  type: ADD_NEW_FILTER_API_ERROR,
  payload: error,
});

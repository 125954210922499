import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";

import {
  REQUEST_PRODUCTS,
  receiveProducts,
  apiError,
} from "../actions/productActions";
import { headers } from "./header";

function* fetchProducts(action) {
  const { currentPage, itemsPerPage } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `https://metaapp-api.dealopia.com/api/products?page=${currentPage}&limit=${itemsPerPage}`,
      {
        headers: headers,
      }
    );
    yield put(receiveProducts(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchFetchProducts() {
  yield takeEvery(REQUEST_PRODUCTS, fetchProducts);
}

export default watchFetchProducts;

import "./OrderPage.css";

const OrderPage = () => {
  return (
    <div className="orderpage-main">
      <p>This order page is in progress</p>
    </div>
  );
};

export default OrderPage;

import { call, put, takeEvery } from "redux-saga/effects";
import {
  REQUEST_IMAGE,
  imageApiError,
  receiveImage,
} from "../actions/imageActions";
import axios from "axios";

function* imagesaga(action) {
  try {
    const response = yield call(
      axios.get,
      "https://metaapp-api.dealopia.com/api/images?page=1"
    );
    if (response.status === 200) {
      yield put(receiveImage(response.data));
    } else {
      yield put(imageApiError(response.statusText));
    }
  } catch (error) {
    yield put(imageApiError(error));
  }
}

function* watchFetchImage() {
  yield takeEvery(REQUEST_IMAGE, imagesaga);
}

export default watchFetchImage;

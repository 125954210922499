import { useState } from "react";
import "./MetaField.css";
import { MetafieldItem } from "../../components/Metafield/MetafieldItem";
import { AddEditModal } from "../../components/Metafield/AddEditModal/AddEditModal";
import { DeleteModal } from "../../components/DeleteModal/DeleteModal";

const MetaField = () => {
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const handleDelete = (id) => {
    setDeleteModalShow(true);
    console.log("delete id: " + id);
  };

  const handleEdit = (id) => {
    setModalShow(true);
    console.log("delete id: " + id);
  };

  return (
    <div className="content-area">
      <div className="headerContainer">
        <h5>
          <b>Metafields</b>
        </h5>
        <div onClick={() => setModalShow(true)} className="metafieldButton">
          Add Metafield
        </div>
      </div>
      <div className="bodyContainer">
        <p className="bodyText">
          You can add up to 5 metafields to apply for filter and search.
        </p>
        <p className="bodyText">
          To add more than 5 metafields for filter and search, please send your
          request to{" "}
          <span className="emailText">customersupport@laitkor.com</span>.
        </p>
      </div>
      <div className="bodyContainer metaListContainer">
        <h6 className="listText">Metafield List</h6>
        <div className="tableListContainer">
          <div className="tableListHeader tableListHeaderText">
            <div className="nameItem">Namespace</div>
            <div className="keyItem">Key</div>
            <div className="appliesItem">Applies to</div>
            <div className="actionItem">Action</div>
          </div>
          {/* table list item */}
          <MetafieldItem
            onEdit={() => handleEdit(5)}
            onDelete={(id) => handleDelete(id)}
          />
          <MetafieldItem />
          <MetafieldItem />
        </div>
      </div>
      {/* add/edit modal */}
      <AddEditModal show={modalShow} onHide={() => setModalShow(false)} />
      {/* delete modal */}
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        headerText="Delete this metafield?"
        bodyText="Deleted metafield cannot be recovered. Do you still want to continue?"
      />
    </div>
  );
};

export default MetaField;

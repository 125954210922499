import { RECEIVE_PRODUCTS, API_ERROR } from "../actions/productActions";

const initialState = {
  data: [],
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;

// saga.js

import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { headers } from "./header";
import {
  REQUEST_FILTER,
  filterApiError,
  receiveFilter,
} from "../actions/getAllFilters";

function* handlePostRequest(action) {
  const { currentPage, itemsPerPage } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `https://metaapp-api.dealopia.com/api/filters/?page=${currentPage}&limit=${itemsPerPage}`,
      { headers: headers }
    );
    yield put(receiveFilter(response.data));
  } catch (error) {
    yield put(filterApiError(error.message));
  }
}

function* watchFetchAllFilter() {
  yield takeLatest(REQUEST_FILTER, handlePostRequest);
}

export default watchFetchAllFilter;

import { useSearchParams } from "react-router-dom";

import Dashboard from "../DashboardPage/DashboardPage";
// import ProductPage from "../ProductPage/ProductPage";
// import Sidebar from "./Sidebar";
import "./Style.css";
import Navbar from "../../components/navbar/Navbar";
import ManageFilter from "../ManageFilter/ManageFilter";
import OrderPage from "../OrderPage/OrderPage";
import Settings from "../../components/Settings/Settings";
import FilterLayout from "../FilterLayout/FilterLayout";
import FilterTree from "../../components/FilterTree/FilterTree";
import VariantPage from "../VariantPage/VariantPage";
import Addfilter from "../AddFilterPage/Addfilter";
import MetaField from "../MetaField/MetaField";
import ThemeSetup from "../ThemeSetup/ThemeSetup";
import ThemeSetupHomepage from "../ThemeSetup/ThemeSetupHomepage";
import MergeValueMain from "../../components/MergeValue/MergeValueMain";
import AddMergedValue from "../../components/MergeValue/AddMergedValue";
import ThemeSetupNextPage from "../ThemeSetup/ThemeSetupNextPage";
import ThemeSetupSyncPage from "../ThemeSetup/ThemeSetupSyncPage";
import EditFilter from "../EditFilterPage/EditFilter";
import { ThemeSetupDisable } from "../ThemeSetup/ThemeSetupDisable";
import { ThemeSetupSuccess } from "../ThemeSetup/ThemeSetupSuccess";

const NestedNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams("");

  const currentParam = searchParams.get("p");
  const id_params = currentParam.split("/");
  const id3 = id_params[2];
  const shopifyThemeId = id_params[1];

  const handleMenuClick = (route) => {
    const cleanedRoute = route.startsWith("/") ? route.substring(1) : route;
    setSearchParams(`p=${cleanedRoute}`);
  };

  // nested routes
  return (
    <div className="homepage-main">
      {/* <Sidebar activePage={activePage} onMenuClick={handleMenuClick} /> */}
      <div className="page-container">
        <Navbar onMenuClick={handleMenuClick} />
        <div className="mobile-hidden">
          {currentParam === "dashboard" && <Dashboard />}
          {/* {currentParam === "products" && <ProductPage />} */}
          {currentParam === "filters" && <ManageFilter />}
          {currentParam === "manage-filters" && <ManageFilter />}
          {currentParam === "settings" && <Settings />}
          {currentParam === "filter-layout" && <FilterLayout />}
          {currentParam === "filter-tree" && <FilterTree />}
          {currentParam === "orders" && <OrderPage />}
          {currentParam === `products/variant/${id3}` && <VariantPage />}
          {currentParam === `add-filter` && <Addfilter />}
          {currentParam === `Metafields` && <MetaField />}
          {currentParam === `theme-setup` && <ThemeSetupHomepage />}
          {currentParam === `theme-disable/${shopifyThemeId}` && (
            <ThemeSetupDisable />
          )}
          {currentParam === `theme-setting` && <ThemeSetup />}
          {currentParam === `theme-success` && <ThemeSetupSuccess />}
          {currentParam === `more` && <MergeValueMain />}
          {currentParam === `merge-values` && <MergeValueMain />}
          {currentParam === `add-merge-value` && <AddMergedValue />}
          {currentParam === `run-setup` && <ThemeSetupNextPage />}
          {currentParam === `sync-theme` && <ThemeSetupSyncPage />}
          {currentParam === `manage-filters/edit-filter/${id3}` && (
            <EditFilter />
          )}
        </div>
      </div>
    </div>
  );
};

export default NestedNavigation;

import "./WelcomePage.css";
import img3 from "../../assets/img3.jpg";
import { Link } from "react-router-dom";

const WelcomePage4 = () => {
  return (
    <div className="welcome-container">
      <div className="content-area">
        <div className="imageAndTextContainer">
          <div className="left-portion">
            <img src={img3} alt="img3" width={450} />
          </div>
          <div className="right-portion">
            <p className="manage_Store">Search Integration</p>
            <p className="slide-text">
              Integrate seamlessly with search functionality, ensuring <br />{" "}
              customers can find products based on metafield values <br />
              effortlessly.
            </p>
            <Link className="welcome-page-link-button" to="/theme-setup">
              <button className="button-next btn">Let's Explore</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage4;

export const dummyData = [
  { Filter_Field: "Title", Filter_Lable: "Title", Input_Type: "Text" },
  {
    Filter_Field: "Product Type",
    Filter_Lable: "Brand",
    Input_Type: "Multiselect Dropdowns",
  },
  { Filter_Field: "Price", Filter_Lable: "Price", Input_Type: "Range Bar" },
  {
    Filter_Field: "Wire Size",
    Filter_Lable: "Wire Size",
    Input_Type: "Range Bar",
  },
  { Filter_Field: "Count", Filter_Lable: "Coil Count", Input_Type: "Text" },
  // Add more dummy data as needed
];

import { useDispatch, useSelector } from "react-redux";
import "./EditFilter.css";
import { Controller, useForm } from "react-hook-form";
import { addNewFilterRequest } from "../../redux/actions/addNewFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestSingleFilter } from "../../redux/actions/getFilterById";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { requestEditFilter } from "../../redux/actions/editFilter";

const inputTypes = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Radio", value: "radio" },
  { label: "Date", value: "date" },
  { label: "Color", value: "color" },
  { label: "Email", value: "email" },
  { label: "File", value: "file" },
  { label: "Password", value: "password" },
  { label: "Range", value: "range" },
  { label: "Search", value: "search" },
  { label: "Telephone", value: "tel" },
  { label: "URL", value: "url" },
];

const EditFilter = () => {
  const [filterLabel, setFilterLabel] = useState("");
  const [filterFiled, setFilterFiled] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams("");

  const currentParam = searchParams.get("p");
  const id_params = currentParam.split("/");
  const id3 = id_params[2];
  const getSingleFilterData = useSelector(
    (state) => state.getSingleFilter.data
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch(requestSingleFilter(id3));
    };

    fetchData();
  }, [dispatch, id3]);

  useEffect(() => {
    getSingleFilterData?.data?.map((item) => {
      setFilterFiled(item?.filter_key);
      setFilterInput(item?.input_type);
      setFilterLabel(item?.filter_lable);
    });
  });

  function OnSubmit(data) {
    const payload = {
      filter_key: data.filterfield,
      filter_lable: data.filterlabel,
      input_type: data.ReactSelect.value,
      store_id: "123456",
      filter_namespace: "example_namespace",
      status: true,
      custom_input_type: "checkbox",
    };
    dispatch(requestEditFilter(payload, id3));

    navigate("/main?p=manage-filters");
  }

  const handleChange = (selected) => {
    setFilterInput(selected);
  };
  return (
    <>
      <div className="content-area">
        <p className="heading">Edit Filters</p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form onSubmit={handleSubmit(OnSubmit)}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="row mt-5 align-items-center">
                <div className="col-auto">
                  <label htmlFor="inputPassword6" className="">
                    Filter Field
                  </label>
                </div>
                <div className="col-auto pl-lg-3 ">
                  <input
                    type=""
                    defaultValue={filterFiled}
                    id="inputPassword6"
                    className="border-black width"
                    aria-describedby="passwordHelpInline"
                    {...register("filterfield", { required: true })}
                  />
                </div>
                <div className="col-auto">
                  <span id="passwordHelpInline" className="form-text">
                    {errors.filterfield && (
                      <span>Please provide Filter Field</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="row mt-5 align-items-center">
                <div className="col-auto">
                  <label htmlFor="inputPassword6" className="">
                    Filter Label
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type=""
                    defaultValue={filterLabel}
                    id="inputPassword6"
                    className="border-black width"
                    aria-describedby="passwordHelpInline"
                    {...register("filterlabel", { required: true })}
                  />
                </div>
                <div className="col-auto">
                  <span id="passwordHelpInline" className="form-text">
                    {errors.filterlabel && (
                      <span>Please provide Filter Label</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="row mt-5 align-items-center">
                <div className="col-auto">
                  <label htmlFor="filterTypeSelect">Filter Type</label>
                </div>
                <div className="col-auto">
                  <Controller
                    name="ReactSelect"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        value={field.value || null}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleChange(selectedOption);
                        }}
                        className="border-black width"
                        options={inputTypes?.map((inputType) => ({
                          label: inputType.label,
                          value: inputType.value,
                        }))}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="col-auto">
                  <span id="passwordHelpInline" className="form-text">
                    {errors.filtertype && (
                      <span>Please provide Filter Type</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="d-flex gap-5">
                <input
                  style={{
                    marginTop: "5rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  type="submit"
                />
                <button
                  style={{
                    marginTop: "5rem",
                    display: "flex",
                    justifyContent: "center",
                    background: "white",
                    borderColor: "black",
                  }}
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditFilter;

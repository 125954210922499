// saga.js

import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { headers } from "./header";
import {
  REQUEST_SINGLE_FILTER,
  receiveSingleFilter,
  singleFilterApiError,
} from "../actions/getFilterById";
import { url } from "../config";

function* handlePostRequest(action) {
  const filter_id = action.payload;
  try {
    const response = yield call(axios.get, `${url}/${filter_id}`, {
      headers: headers,
    });
    yield put(receiveSingleFilter(response.data));
  } catch (error) {
    yield put(singleFilterApiError(error.message));
  }
}

function* watchSingleFilter() {
  yield takeLatest(REQUEST_SINGLE_FILTER, handlePostRequest);
}

export default watchSingleFilter;

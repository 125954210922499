import { RECIEVE_VARIANT, VARIANT_API_ERROR } from "../actions/variantActions";

const initialState = {
  data: [],
  error: null,
};

const variantReducer = (state = initialState, action) => {
    switch (action.type) {
    case RECIEVE_VARIANT:
      return {
        ...state,
        data: action.payload,
        error: null,
      };

    case VARIANT_API_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default variantReducer;

// saga.js

import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { headers } from "./header";
import {
  REQUEST_DELETE_FILTER,
  deleteFilterApiError,
  receiveDeleteFilter,
} from "../actions/deleteFilterActions";

function* handlePostRequest(action) {
  try {
    const response = yield call(
      axios.delete,
      `https://metaapp-api.dealopia.com/api/filters/fae92ddb-b645-4bc3-b516-87c6b77ed59b`,
      { headers: headers }
    );
    yield put(receiveDeleteFilter(response.data));
  } catch (error) {
    yield put(deleteFilterApiError(error.message));
  }
}

function* watchDeleteFilter() {
  yield takeLatest(REQUEST_DELETE_FILTER, handlePostRequest);
}

export default watchDeleteFilter;

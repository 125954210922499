// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-dropdown {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.select-dropdown {
  padding: 5px;
  border: 1px solid rgba(48, 48, 48, 1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  color: rgba(48, 48, 48, 1);
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomPagerDropdown/CustomPagerDropdown2.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,qCAAqC;EACrC,kBAAkB;EAClB,kCAAkC;EAClC,0BAA0B;AAC5B","sourcesContent":[".pagination-dropdown {\n  padding-left: 10px;\n  padding-top: 10px;\n  padding-bottom: 8px;\n}\n\n.select-dropdown {\n  padding: 5px;\n  border: 1px solid rgba(48, 48, 48, 1);\n  border-radius: 8px;\n  background: rgba(255, 255, 255, 1);\n  color: rgba(48, 48, 48, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

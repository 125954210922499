export const REQUEST_SINGLE_FILTER = "REQUEST_SINGLE_FILTER";
export const RECIEVE_SINGLE_FILTER = "RECIEVE_SINGLE_FILTER";
export const SINGLE_FILTER_API_ERROR = "SINGLE_FILTER_API_ERROR";

export const requestSingleFilter = (filter_id) => ({
  type: REQUEST_SINGLE_FILTER,
  payload: filter_id,
});

export const receiveSingleFilter = (filter) => ({
  type: RECIEVE_SINGLE_FILTER,
  payload: filter,
});

export const singleFilterApiError = (error) => ({
  type: SINGLE_FILTER_API_ERROR,
  payload: error,
});

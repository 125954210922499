import axios from "axios";
import {
  REQUEST_VARIANT_BY_PRODUCT,
  apiError,
  receiveVariantByProduct,
} from "../actions/variantByProductsAction";
import { call, put, takeEvery } from "redux-saga/effects";
import { headers } from "./header";

function* fetchVariantByProductsSaga(action) {
  try {
    const productId = action.payload;
    const response = yield call(
      axios.get,
      `https://metaapp-api.dealopia.com/api/products/${productId}/variants`,
      { headers: headers }
    );
    yield put(receiveVariantByProduct(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchfetchVariantByProductsSaga() {
  yield takeEvery(REQUEST_VARIANT_BY_PRODUCT, fetchVariantByProductsSaga);
}

export default watchfetchVariantByProductsSaga;

import "./PlanStatusCSS.css";

const PlanStatus = () => {
  return (
    <div className="planstatus">
      <div className="d-flex justify-content-between">
        <h4 className="dashboard-component-heading">Plan Status</h4>
        <p
          style={{
            marginLeft: "0",
            background: "rgba(255, 235, 120, 1)",
            color: "rgba(48, 48, 48, 1)",
          }}
        >
          Trial
        </p>
      </div>
      <p className="dashboard-component-content">
        7 days left of your trial <br />
        Ready to subscribe?
      </p>
      <button className="dashboard-component-new-button">Choose Plan</button>
    </div>
  );
};

export default PlanStatus;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-top: auto;
  margin-bottom: auto;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #fff;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #00f260;
}
`, "",{"version":3,"sources":["webpack://./src/components/Switch/Switch.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,mBAAmB;EACnB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,sCAAsC;AACxC;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".toggle-switch {\n  position: relative;\n  display: inline-block;\n  width: 50px;\n  height: 25px;\n  margin-top: auto;\n  margin-bottom: auto;\n}\n.toggle-switch input[type=\"checkbox\"] {\n  display: none;\n}\n.toggle-switch .switch {\n  position: absolute;\n  cursor: pointer;\n  background-color: #ccc;\n  border-radius: 25px;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  transition: background-color 0.2s ease;\n}\n.toggle-switch .switch::before {\n  position: absolute;\n  content: \"\";\n  left: 2px;\n  top: 2px;\n  width: 21px;\n  height: 21px;\n  background-color: #aaa;\n  border-radius: 50%;\n  transition: transform 0.3s ease;\n}\n.toggle-switch input[type=\"checkbox\"]:checked + .switch::before {\n  transform: translateX(25px);\n  background-color: #fff;\n}\n.toggle-switch input[type=\"checkbox\"]:checked + .switch {\n  background-color: #00f260;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from "react";
import "./ThemeStatusCSS.css";
import layout from "../../../../assets/layout.svg";
import { integrateThemeStatus } from "../../../../services";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ThemeStatus = () => {
  const navigation = useNavigate();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return async () => {
      setLoading(true);
      const data = await integrateThemeStatus();
      if (data === false) {
        setStatus(false);
        setLoading(false);
        return;
      }

      if (data === null) {
        setLoading(false);
        toast.error("Something went wrong. Please try again later");
        return;
      }

      setStatus(data);
      setLoading(false);
      return;
    };
  }, []);

  const handleNavigate = () => {
    navigation("/main?p=theme-setup");
  };

  return (
    <div className="themestatus">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <h4 className="dashboard-component-heading">Theme Status</h4>
      <p className="dashboard-component-content">
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : !status ? (
          <>
            Filters are not integrated into any theme yet. Choose a theme to
            integrate filters when you are ready.
          </>
        ) : (
          <>
            Filters are currently integrated into <b>{status}</b> theme
          </>
        )}
      </p>
      <button className="dashboard-component-button" onClick={handleNavigate}>
        <span className="d-flex gap-2">
          <img src={layout} alt="layout.svg" />
          Integrate Theme
        </span>
      </button>
    </div>
  );
};

export default ThemeStatus;

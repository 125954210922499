// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600&family=Roboto:wght@300;400&display=swap");

.product-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 30px;
  font-family: "Roboto Slab", Helvetica;
  margin-top: 30px;
  margin-bottom: 25px;
}

.filter-pagination {
  display: flex;
  justify-content: space-between;
}

.add-filter-button {
  margin-top: 0;
  font-weight: 800;
  height: 100%;
}

.table-container {
  width: 100%;
}

th {
  text-align: center;
  vertical-align: middle;
  padding: 20px 57px 20px 57px;
  background-color: #3a3a40;
  font-size: 20px;
  border-color: black;
  position: sticky;
  color: white;
  top: 0;
}

.table-data {
  padding: 17px;
  font-size: 18px;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
}

.table2 {
  border-spacing: 0px;
  text-align: center;
  overflow-y: auto;
}

.table tbody {
  overflow-y: auto;
}

#table-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#button {
  background-color: white;
  border: 1px solid rgb(197, 197, 197);
  font-weight: 500;
  margin: 0;
}

.search-input-filter {
  width: 50%;
  margin-bottom: 25px;
  padding: 5px;
  font-weight: 700;
  font-size: 17px;
  border-radius: 10px;
  border-color: transparent;
  border: 1px solid;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Settings/Settings.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAuFG","sourcesContent":["/* @import url(\"https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600&family=Roboto:wght@300;400&display=swap\");\n\n.product-container {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.heading {\n  font-size: 30px;\n  font-family: \"Roboto Slab\", Helvetica;\n  margin-top: 30px;\n  margin-bottom: 25px;\n}\n\n.filter-pagination {\n  display: flex;\n  justify-content: space-between;\n}\n\n.add-filter-button {\n  margin-top: 0;\n  font-weight: 800;\n  height: 100%;\n}\n\n.table-container {\n  width: 100%;\n}\n\nth {\n  text-align: center;\n  vertical-align: middle;\n  padding: 20px 57px 20px 57px;\n  background-color: #3a3a40;\n  font-size: 20px;\n  border-color: black;\n  position: sticky;\n  color: white;\n  top: 0;\n}\n\n.table-data {\n  padding: 17px;\n  font-size: 18px;\n}\n\n.table-container {\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.table2 {\n  border-spacing: 0px;\n  text-align: center;\n  overflow-y: auto;\n}\n\n.table tbody {\n  overflow-y: auto;\n}\n\n#table-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n#button {\n  background-color: white;\n  border: 1px solid rgb(197, 197, 197);\n  font-weight: 500;\n  margin: 0;\n}\n\n.search-input-filter {\n  width: 50%;\n  margin-bottom: 25px;\n  padding: 5px;\n  font-weight: 700;\n  font-size: 17px;\n  border-radius: 10px;\n  border-color: transparent;\n  border: 1px solid;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState, useEffect } from "react";
import usePagination from "../hooks/usePagination";

const Pagination = ({ itemsPerPage, requestData, data, disabled }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  usePagination(currentPage, itemsPerPage, requestData);
  useEffect(() => {
    setTotalPage(data?.totalPages);
  }, [data]);

  const nextPage = () => {
    if (currentPage === totalPage) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const generatePaginationButtons = (data) => {
    const buttons = [];
    for (let i = 1; i <= totalPage; i++) {
      buttons.push(
        <button
          disabled={data}
          key={i}
          onClick={() => changePage(i)}
          style={{
            background:
              currentPage === i
                ? "linear-gradient(135deg, #00F260 0.81%, #0575E6 100.81%"
                : "rgba(224, 224, 224, 1)",
            color: currentPage === i ? "#fff" : "#000",
            fontFamily: "Roboto",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "14.06px",
            padding: "8px 9px 8px 9px",
            border: "none",
            borderRadius: "8px",
          }}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <>
      {disabled ? (
        <>
          <div className="pagination-button">
            {generatePaginationButtons(true)}
          </div>
        </>
      ) : (
        <>
          <div className="pagination-button">
            {generatePaginationButtons(false)}
          </div>
        </>
      )}
    </>
  );
};

export default Pagination;

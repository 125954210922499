import { API_ERROR_VARIANT_BY_PRODUCT, RECEIVE_VARIANT_BY_PRODUCT } from "../actions/variantByProductsAction"

const intitalState = {
    data: [],
    error: null
}

const variantByProductReducer = (state = intitalState, action) => {
    switch (action.type) {
        case RECEIVE_VARIANT_BY_PRODUCT:
            return {
                ...state,
                data: action.payload,
                error: null
            }
        case API_ERROR_VARIANT_BY_PRODUCT:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}

export default variantByProductReducer
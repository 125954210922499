import React from "react";

export const ThemeSetupSuccess = () => {
  return (
    <div className="content-area">
      <div className="successHeaderContainer my-4">
        <div className="backIconContainer">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div className="headerTextContainer">
          <h5>
            <b>Theme Setup</b>
          </h5>
        </div>
      </div>
      <div className="my-4 successContainer">
        <h6 className="successHeaderText">
          <b>Theme setup complete!</b>
        </h6>
        <div className="successTextContainer">
          <p className="successText">
            MetaXpert app has been successfully installed on the selected theme.
          </p>
          <p className="successText">
            Explore your theme editor to verify the proper placement of all
            widgets and ensure everything functions correctly.
          </p>
          <p className="successText">
            <b>Note:</b> Your customers won't be able to see the filter until
            you publish the <b>Dawn</b> theme.
          </p>
        </div>
        <button className="successPreviewBtn">Preview</button>
      </div>
    </div>
  );
};

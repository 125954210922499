/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import "./ThemeSetup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getThemes,
  getFeatures,
  getSelectedFeatures,
  themeSetup,
} from "../../services";
import { storeItem, getItem } from "../../utils";

const ThemeSetup = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isThemeSettingsPage = queryParams.get("p");
  const navigation = useNavigate();
  const [checkedItems, setCheckedItems] = useState([]);
  const [theme, setTheme] = useState(null);
  const [isLiveTheme, setIsLiveTheme] = useState(false);
  const [themeList, setThemeList] = useState(null);
  const [themeLoading, setThemeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState(null);
  const [selectedFeatureList, setSelectedFeatureList] = useState(null);

  const handleCheckboxChange = (e) => {
    const checkId = e.target.value;
    if (e.target.checked) {
      setCheckedItems((items) => [...items, checkId]);
    } else {
      setCheckedItems(checkedItems.filter((id) => id !== checkId));
    }
  };

  const storeSelectedAndNavigate = async () => {
    setLoading(true);
    storeItem("selectedThemeId", theme.shopify_theme_id);
    storeItem("selectedFeatureIds", checkedItems);

    const shopId = getItem("shopId");
    const data = await themeSetup(shopId, theme.shopify_theme_id, checkedItems);

    if (data === null) {
      setLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }

    if (data.status === 201) {
      setLoading(false);

      if (isThemeSettingsPage === "theme-setting")
        navigation("/main?p=run-setup");
      else navigation("/run-setup");
      return;
    }

    setLoading(false);
    return;
  };

  const goToNextPage = () => {
    if (theme.role === "main") {
      setIsLiveTheme(true);
      return;
    }

    storeSelectedAndNavigate();
    return;
  };

  const goNextPageLiveTheme = () => {
    storeSelectedAndNavigate();
    return;
  };

  const goToDashboard = () => {
    navigation("/main?p=dashboard");
  };

  const getTheme = async () => {
    setThemeLoading(true);
    const data = await getThemes();
    if (data === null) {
      setThemeList(data);
      setThemeLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }
    setThemeList(data);
    setThemeLoading(false);
    return;
  };

  const getFeature = async () => {
    setThemeLoading(true);
    const data = await getFeatures();
    if (data === null) {
      setFeatureList(data);
      setThemeLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }
    setFeatureList(data);
    setThemeLoading(false);
    return;
  };

  useEffect(() => {
    return () => {
      getTheme();
      getFeature();
    };
  }, []);

  const handleThemeChange = async (index) => {
    if (index === "Choose a theme") {
      setTheme(null);
      setCheckedItems([]);
      return;
    }

    setThemeLoading(true);
    const theme = themeList[index];
    setTheme(theme);
    const { shopify_theme_id, shop_id } = theme;
    const data = await getSelectedFeatures(shopify_theme_id, shop_id);
    if (data === null) {
      setSelectedFeatureList(data);
      setThemeLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }
    setSelectedFeatureList(data);
    setCheckedItems([]);
    data.map((feature) => {
      if (feature.status === true) {
        setCheckedItems((items) => [...items, feature.feature_id]);
      }
      return null;
    });
    setThemeLoading(false);
    return;
  };

  const handleThemeRefreshClick = () => {
    getTheme();
    return;
  };

  const handleModalClose = () => setIsLiveTheme(false);

  return (
    <div
      className={`content-area ${
        isThemeSettingsPage === "theme-setting" ? "pt-4" : "pt-5 pb-2"
      }`}
    >
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <div
        style={{
          background: "#ffffff",
          padding: "20px",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <h4 style={{ fontFamily: "Roboto", fontWeight: "700" }}>Theme Setup</h4>
        <p style={{ fontSize: "14px", marginLeft: "0", fontFamily: "Roboto" }}>
          When the theme setup process is complete, the automatic installation
          of MetaXpert features into a specific theme is considered successful.
          <hr />
        </p>
        <div className="mt-4">
          <p
            style={{
              fontSize: "14px",
              marginLeft: "0",
              fontFamily: "Roboto",
              fontWeight: "600",
            }}
          >
            <span>1. &nbsp;</span>Select a theme to install our application on:
          </p>
          <p
            style={{
              marginLeft: "0",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Roboto",
            }}
          >
            Our app is most compatible with theme OS 2.0. To prevent any bad
            effect on your live theme (ex: broken layout), we strongly recommend
            you run theme setup on an unpublished theme or a duplicate of your
            live theme first
          </p>

          <p
            style={{
              marginLeft: "0",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Roboto",
            }}
          >
            Go to{" "}
            <a
              href="https://help.shopify.com/en/manual/online-store/themes/managing-themes/duplicating-themes"
              target="blank"
            >
              Online Store &gt; Theme &gt; Theme Library &gt; ... button &gt;
              Duplicate{" "}
            </a>{" "}
            to duplicate a theme that you want to work on.
          </p>
          <div className="dropdownParent">
            <select
              className="form-select form-select-sm mt-4 border"
              style={{
                width: "50%",
                marginLeft: "0",
                height: "40px",
                color: "#454545",
              }}
              aria-label="small select example"
              onChange={(e) => handleThemeChange(e.target.value)}
            >
              <option> Choose a theme </option>
              {themeList?.map((theme, key) => {
                return (
                  <option key={key} value={key}>
                    {theme.name}
                  </option>
                );
              })}
            </select>
            <div className="refreshBtnParent mt-4 ms-3">
              <i
                onClick={() => handleThemeRefreshClick()}
                className={`fa fa-sync ${
                  themeLoading ? "fa-spin" : ""
                } refreshBtn`}
              />
            </div>
          </div>
          <p
            style={{
              marginTop: "30px",
              marginLeft: "0",
              fontSize: "14px",
              fontWeight: "600",
              fontFamily: "Roboto",
            }}
          >
            <span>2. &nbsp;</span>Which feature would you like to install?
          </p>
        </div>
        {featureList &&
          selectedFeatureList === null &&
          featureList.map((item, index) => (
            <div key={index} class="form-check">
              <input
                class="form-check-input"
                name="checkbox"
                type="checkbox"
                id={`checkbox-${index}`}
                value={item.feature_id}
                checked={checkedItems.includes(item.feature_id) || false}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <label class="form-check-label" for={`checkbox-${index}`}>
                {item.feature}
              </label>
            </div>
          ))}
        {featureList &&
          selectedFeatureList &&
          selectedFeatureList.map((item, index) => (
            <div key={index} class="form-check">
              <input
                class="form-check-input"
                name="checkbox"
                type="checkbox"
                id={`checkbox-${index}`}
                value={item.feature_id}
                checked={checkedItems.includes(item.feature_id) || false}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <label class="form-check-label" for={`checkbox-${index}`}>
                {item.feature}
              </label>
            </div>
          ))}
        <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
          <button className="button-primary" onClick={goToDashboard}>
            I'll do it later
          </button>
          {theme === null || checkedItems.length === 0 ? (
            <button
              disabled
              className="button-secondary mr-3"
              style={{ width: "60px" }}
            >
              Next
            </button>
          ) : (
            <button
              className="button-secondary mr-3"
              style={{ width: "60px" }}
              onClick={goToNextPage}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Next"}
            </button>
          )}
        </div>
      </div>
      <Modal show={isLiveTheme} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Warning: You're about to run theme setup on a live theme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="content" style={{ marginBottom: "20px" }}>
              To minimize unexpected impacts on your live theme, such as a
              broken layout, we strongly recommend you run the theme set up on
              an unpublished theme or a duplicate of your live theme.
            </p>
            <p className="content">
              Go to{" "}
              <a
                target="blank"
                href="https://help.shopify.com/en/manual/online-store/themes/managing-themes/duplicating-themes"
              >
                Online Store &gt; Theme &gt; Theme Library &gt; ... button &gt;
                Duplicate{" "}
              </a>{" "}
              to duplicate a theme that you want to work on.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button-primary" onClick={handleModalClose}>
            Select another theme
          </button>
          <button
            className="button-secondary"
            style={{ width: "160px" }}
            onClick={goNextPageLiveTheme}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Yes, run setup anyway"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThemeSetup;

import { useEffect, useState } from "react";
import "./SyncStoreCSS.css";
import moment from "moment";
import { getSynchDateTime, productSync } from "../../../../services";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SyncStore = () => {
  const [syncedDateTime, setSyncedDateTime] = useState("...");
  const [dateTimeLoading, setDateTimeLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [callSyncDateTime, setCallSyncDateTime] = useState(false);

  const handleSync = async () => {
    setSyncLoading(true);
    const data = await productSync();

    if (data === null) {
      setSyncLoading(false);
      toast.error("Something went wrong. Please try again later");
      return;
    }

    console.log("sync data: ", data);
    setCallSyncDateTime(!callSyncDateTime);
    setSyncLoading(false);
    return;
  };

  useEffect(() => {
    return async () => {
      setDateTimeLoading(true);
      const data = await getSynchDateTime();

      if (data === null) {
        setDateTimeLoading(false);
        toast.error("Something went wrong. Please try again later");
        return;
      }

      const formattedDateTime = moment(data).format("MMMM Do YYYY, h:mm:ss a");
      // console.log("sync time: ", data);
      setSyncedDateTime(formattedDateTime);
      setDateTimeLoading(false);
      return;
    };
  }, [callSyncDateTime]);

  return (
    <div className="syncStore">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      <div className="d-flex justify-content-between mb-2">
        <h4 className="dashboard-component-heading">Sync your online store</h4>
        <button onClick={handleSync} className="dashboard-component-new-button">
          {syncLoading ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            "Sync Now"
          )}
        </button>
      </div>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex gap-2">
          {dateTimeLoading ? (
            <>
              <Spinner animation="border" size="sm" variant="dark" />
              <span className="estimated-time-title">
                Exporting products...
              </span>
            </>
          ) : (
            <>
              <span className="estimated-time-title">Last synced at:</span>
              <span className="estimated-time-span">{syncedDateTime}</span>
            </>
          )}
          {/* <Spinner animation="border" size="sm" variant="dark" />
          <span className="estimated-time-title">Exporting products...</span> */}
        </div>
        {/* <div className="d-flex gap-2">
          <span className="estimated-time-title">Takes about ~</span>
          <span className="estimated-time-span">2 minute(s)</span>
        </div> */}
      </div>
      <p className="dashboard-component-content">
        After 1-2 minutes since you make changes to the product data, the app
        will sync product data. If you don't see the app updating the latest
        data, please apply this feature to manually sync data.
      </p>
    </div>
  );
};

export default SyncStore;

import { put, takeEvery, call } from "redux-saga/effects";
import {
  REQUEST_IMAGE_BY_PRODUCT,
  apiError,
  recieveImageByProduct,
} from "../actions/imageByProduct";
import axios from "axios";
import { headers } from "./header";

function* fetchImageByProduct(action) {
  try {
    const productId = action.payload;
    const repsonse = yield call(
      axios.get,
      `https://metaapp-api.dealopia.com/api/products/${productId}/images`,
      {
        headers: headers,
      }
    );
    yield put(recieveImageByProduct(repsonse.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchFetchImageByProduct() {
  yield takeEvery(REQUEST_IMAGE_BY_PRODUCT, fetchImageByProduct);
}

export default watchFetchImageByProduct;

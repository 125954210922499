import { Link } from "react-router-dom";
import "./MergeValue.css";

import React from "react";

const MergeValueMain = () => {
  return (
    <div className="ml-5 mt-5">
      <div className="d-flex justify-content-between">
        <h5>Merge Values</h5>
        <Link to="/main?p=add-merge-value">
          <button
            style={{
              width: "10rem",
              borderRadius: "10px",
              background: "#008060",
              color: "#ffffff",
              marginRight: "5rem",
            }}
          >
            Add merged values
          </button>
        </Link>
      </div>
      <div className="d-flex justify-content-center">
        <table
          style={{
            width: "100%",
            fontSize: "10px",
            marginLeft: "5rem",
            marginRight: "5rem",
            marginTop: "50px",
            display: "inline-flex",
            justifyContent: "space-evenly",
          }}
        >
          <thead>
            <tr style={{}}>
              <th
                style={{
                  background: "white",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "400",
                  fontFamily: "revert-layer",
                  borderBottom: "2px solid",
                }}
              >
                Status
              </th>
              <th
                style={{
                  background: "white",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "400",
                  fontFamily: "revert-layer",
                  borderBottom: "2px solid",
                }}
              >
                Marged Type
              </th>
              <th
                style={{
                  background: "white",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "400",
                  fontFamily: "revert-layer",
                  borderBottom: "2px solid",
                }}
              >
                Actions{" "}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default MergeValueMain;

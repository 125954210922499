// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-inner {
  color: rgb(17, 17, 17) !important;
  background-color: #d7d6d6 !important;
  /* border: 1px solid #ccc !important; */
  margin-bottom: -5.5px !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #d7d6d6 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,oCAAoC;EACpC,uCAAuC;EACvC,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".tooltip-inner {\n  color: rgb(17, 17, 17) !important;\n  background-color: #d7d6d6 !important;\n  /* border: 1px solid #ccc !important; */\n  margin-bottom: -5.5px !important;\n}\n\n.bs-tooltip-top .tooltip-arrow::before {\n  border-top-color: #d7d6d6 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

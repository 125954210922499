import { useNavigate, useSearchParams } from "react-router-dom";
import { getItem } from "../../utils";

export const ThemeSetupDisable = () => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams("");

  const shopifyThemeId = searchParams.get("p").split("/")[1];

  const handleNavigate = () => {
    const store = getItem("shop").split(".")[0]; // get store from shop domain
    const url = `https://admin.shopify.com/store/${store}/themes/${shopifyThemeId}/editor?previewPath=%2Fcollections%2Fall&context=apps`;
    window.open(url, "_blank"); // Open link in a new tab
  };

  return (
    <div className="content-area">
      <div className="my-4 disableContainer">
        <div className="disableHeaderContainer">
          <h3>Theme setup</h3>
        </div>
        <div className="disableBodyContainer">
          <p className="disableText">
            Disable <b>MetaXpert</b> app embed blocks in{" "}
            <span className="disableThemeText">
              Theme -&gt; customize -&gt; App embeds
            </span>
          </p>
        </div>
        <div className="disableButtonContainer">
          <button className="backBtn" onClick={() => navigation(-1)}>
            Back
          </button>
          <button className="GoToThemeBtn" onClick={handleNavigate}>
            Go to Theme
          </button>
        </div>
      </div>
    </div>
  );
};

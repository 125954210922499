export const REQUEST_FILTER = "REQUEST_FILTER";
export const RECIEVE_FILTER = "RECIEVE_FILTER";
export const GET_FILTER_API_ERROR = "GET_FILTER_API_ERROR";

export const requestFilter = (currentPage, itemsPerPage) => ({
  type: REQUEST_FILTER,
  payload: { currentPage, itemsPerPage },
});

export const receiveFilter = (image) => ({
  type: RECIEVE_FILTER,
  payload: image,
});

export const filterApiError = (error) => ({
  type: GET_FILTER_API_ERROR,
  payload: error,
});

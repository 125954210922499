import { fork } from "redux-saga/effects";
import watchFetchProducts from "./productSagas";
import watchFetchVariants from "./variantSagas";
import watchFetchImage from "./imageSagas";
import watchfetchVariantByProductsSaga from "./variantByProductsSaga";
import watchFetchImageByProduct from "./imageByProductSaga";
import watchAddNewFilter from "./addNewFilterSaga";
import watchFetchAllFilter from "./getAllFiltersSaga";
import watchDeleteFilter from "./deleteFilterSaga";
import watchSingleFilter from "./getFilterByIdSaga";
import watchEditFilter from "./editFiltersSaga";

export default function* rootSaga() {
  yield fork(watchFetchProducts);
  yield fork(watchFetchVariants);
  yield fork(watchFetchImage);
  yield fork(watchfetchVariantByProductsSaga);
  yield fork(watchFetchImageByProduct);
  yield fork(watchAddNewFilter);
  yield fork(watchFetchAllFilter);
  yield fork(watchEditFilter);
  yield fork(watchSingleFilter);
  yield fork(watchDeleteFilter);
}

import { Routes, Route } from "react-router-dom";
import  NestedNavigation  from "../pages/_nestedNavigation/NestedNavigation";
import Dashboard from "../pages/DashboardPage/DashboardPage";
import WelcomePage from "../pages/WelcomePage/WelcomePage";
import ThemeSetup from "../pages/ThemeSetup/ThemeSetup";
import ThemeSetupNextPage from "../pages/ThemeSetup/ThemeSetupNextPage";
import ThemeSetupSyncPage from "../pages/ThemeSetup/ThemeSetupSyncPage";
// import { useEffect } from "react";
import Connect from "../connect";
// import { useShop } from "../ShopContext";

const Navigation = () => {

  // main routes
  return (
    <Routes>
      <Route path="/" Component={WelcomePage} />
      <Route path="/theme-setup" Component={ThemeSetup} />
      <Route path="/run-setup" Component={ThemeSetupNextPage} />
      <Route path="/sync-theme" Component={ThemeSetupSyncPage} />
      {/* <Route path="/install-widget" Component={WelcomePage2} />
      <Route path="/install-sucess" Component={WelcomePage3} /> */}
      <Route path="/connect" Component={Connect} />
      <Route path="/main/*" Component={NestedNavigation} />
      <Route path="/dashboard" Component={Dashboard} />
    </Routes>
  );
};

export default Navigation;

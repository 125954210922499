// saga.js

import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { headers } from "./header";
import {
  REQUEST_EDIT_FILTER,
  editFilterApiError,
  receiveEditFilter,
} from "../actions/editFilter";
import { url } from "../config";

function* handlePostRequest(action) {
  const data = action.payload;
  const payload = data?.payload;
  const filter_id = data?.filter_id;
  try {
    const response = yield call(axios.put, `${url}/${filter_id}`, payload, {
      headers: headers,
    });
    yield put(receiveEditFilter(response.data));
  } catch (error) {
    yield put(editFilterApiError(error.message));
  }
}

function* watchEditFilter() {
  yield takeLatest(REQUEST_EDIT_FILTER, handlePostRequest);
}

export default watchEditFilter;

import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import Navigation from "./Navigation/Navigation";
import { ShopProvider } from "./ShopContext";

function App() {
  return (
    <>
      <div className="app-container">
        <Router>
          <div className="app-content">
            <ShopProvider>
              <Navigation />
            </ShopProvider>
          </div>
        </Router>
      </div>
    </>
  );
}

export default App;
